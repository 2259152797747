import './BitcoinConsumesMobile.scss';

import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';

import Tvs from './../../assets/ccc-energy-tvs.jpg';
import Kettle from './../../assets/ccc-energy-kettle.jpg';
import Uae from './../../assets/ccc-energy-uae.jpg';

export default function BitcoinConsumesMobile() {
  const carouselBreakpoints = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  }

  return (
    <section className="ccc-energy-carousel bitcoin-consumes mobile">
      <div className="container">
        <h2>Did you know?<br /> Bitcoin consumes as much energy as:</h2>
        <Carousel responsive={carouselBreakpoints} infinite={true} showDots={true}>
          <div className="ccc-energy-slide">
            <p><strong>All the TVs in the US</strong></p>
            <img src={Tvs} alt="Stack of old CRT televisions" />
          </div>
          <div className="ccc-energy-slide">
            <p><strong>All the teakettles in the UK, boiling continuously for 18 years</strong></p>
            <img src={Kettle} alt="Tea kettle on stove boiling" />
          </div>
          <div className="ccc-energy-slide">
            <p><strong>The United Arab Emirates</strong></p>
            <img src={Uae} alt="Skyline of Dubai" />
          </div>
        </Carousel>
      </div>
    </section>
  )
}
