import { useEffect, useState } from 'react';
import { API_URL } from 'assets';
import './News.scss';
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import Post from 'components/Post/Post';

import DownArrow from 'img/arrow-down.svg';
import Loading from 'img/loading.svg';


export default function News( initialLoad ) {
  const [newsPosts, setNewsPosts] = useState([]);

  const [pinnedNews, setPinnedNews] = useState([])
  const [recentNews, setRecentNews] = useState([]);
  const [remaining, setRemaining] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const carouselBreakpoints = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  }

  const getPosts = async () => {
    setIsLoadingMore(true);

    const response = await fetch(`${API_URL}/posts?acf_news_article=1&per_page=8&page=${currentPage}&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_links.author,_embedded.wp:term,_embedded.author&v=2`);
    const data = await response.json();

    // setNewsPosts(data);
    setTotalPages(response.headers.get('x-wp-totalpages'));
    setNewsPosts(curr => [...curr, ...data]);
    setIsLoadingMore(false);
  }

  const separateNews = () => {
    const pinned = newsPosts.filter(post => post.acf.pinned_news_article === true);
    const recent = newsPosts.slice(0, 4);
    const remainder = newsPosts.slice(4);

    if (!pinnedNews.length) {
      setPinnedNews(pinned)
    }
    if (!recentNews.length) {
      setRecentNews(recent);
    }
    setRemaining(remainder);
  }

  useEffect(() => {
      getPosts();
  }, [currentPage])

  useEffect(() => {
    separateNews();
  }, [newsPosts])


  if (!recentNews.length & !remaining.length) return null;

  return (
    <section className="page-content news">
      <div className="container">
        <div className="pinned-display">
          <Carousel responsive={carouselBreakpoints} infinite={true}>
            {pinnedNews.map(post => (
              <Post post={post} key={post.id} />
            ))}
          </Carousel>
        </div>
        <div className="recent-display">
          <div className="display-one">
            <div className="post-container">
              <Post post={recentNews[0]} key={recentNews[0].id} />
              <Post post={recentNews[1]} key={recentNews[1].id} />
            </div>
            <div className="subscribe">
              <h5>Subscribe to our newsletter to stay up to date on all things Arts Help!</h5>
              <input type="text" placeholder="EMAIL" />
              <button>Sign me up!</button>
            </div>
          </div>
          <div className="display-two">
            <div className="post-container">
              <Post post={recentNews[2]} key={recentNews[2].id} />
              <Post post={recentNews[3]} key={recentNews[3].id} />
            </div>
          </div>
        </div>
        <div className="default-display">
          {remaining.map(post => (
            <Post post={post} key={post.id} />
          ))}
          {currentPage < totalPages ?
            <button onClick={() => setCurrentPage(curr => curr + 1)} disabled={isLoadingMore} className="load-more">
              {isLoadingMore ?
                <>
                  LOADING...
                  <img src={Loading} className="load-asset load-activity" />
                </>
                :
                <>
                  LOAD MORE
                  <img src={DownArrow} className="load-asset load-more-arrow" />

                </>
              }
            </button>
            : null
          }
        </div>
      </div>
    </section>
  )
}
