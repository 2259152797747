export const ColorChecker = (color) => {
  // Remove the '#' character from the color string
  color = color.replace('#', '');
//
  // Convert the color to RGB
  const red = parseInt(color.substr(0, 2), 16);
  const green = parseInt(color.substr(2, 2), 16);
  const blue = parseInt(color.substr(4, 2), 16);

  // Calculate the brightness using the formula: (R * 299 + G * 587 + B * 114) / 1000
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

  // Return true if the brightness is less than or equal to 125 (adjust the threshold as needed)
  return brightness <= 125;
};
