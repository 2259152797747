import { useState, useEffect } from 'react';
import { API_URL } from 'assets';
import './ArticleRow.scss';
import Post from 'components/Post/Post';

export default function ArticleRow({ data }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const postsResponse = await fetch(`${API_URL}/posts?include=${data.articles.join(',')}&orderby=include&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const postsItems = await postsResponse.json();

      setPosts(postsItems);
    }

    getPosts();
  }, [data.articles])

  if (!posts.length) return null;

  return (
    <section className={`article-row count-${posts.length} layout-${data.layout}`}>
      <div className="articles container">
        {posts.map(post => (
          <Post post={post} key={post.id} />
        ))}
      </div>
    </section>
  )
}
