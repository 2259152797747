import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { API_URL } from 'assets';
import './Category.scss';

import Lottie from 'lottie-react';
import LoadingAnimation from 'assets/loading-loop.json'

import CategoriesNav from 'pages/Editorial/components/CategoriesNav/CategoriesNav'
import Hero from './components/Hero/Hero';
import Component from '../../components/Component';

export default function Category() {
  const params = useParams();
  const [page, setPage] = useState(null);

  useEffect(() => {
    const getPage = async () => {
      const response = await fetch(`${API_URL}/categories?slug=${params.slug}&_fields=id,name,acf`);
      const data = await response.json();

      setPage(data[0]);
    }

    getPage();
  }, [params.slug]);


  if (!page) return (
    <div className="container">
      <Lottie animationData={LoadingAnimation} loop={true} className="loading-animation"/>
    </div>
  );

  return (
    <div className="page category">
      <Helmet>
        <title>{page.name} | Arts Help</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <CategoriesNav />
      <Hero category={page.id} categoryName={page.name} categoryColor={page.acf.color} featuredArticle={page.acf.featured_article} />
      {page.acf.editorial_components && page.acf.editorial_components.map((component, index) => <Component component={component} key={index} pageData={page} />)}
    </div>
  )
}
