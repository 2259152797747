import { useState, useEffect } from 'react';
import './Excerpt.scss'

export default function Excerpt({ data }) {

  useEffect(() => {
    console.log(data);
  }, [data])

  return (
    <div className="excerpt">
      <p>{data.text}</p>
    </div>
  )
}
