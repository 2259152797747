import { useState, useEffect } from 'react'
import './Author.scss'

export default function Author(post) {
  const user = post.user[0];

  if (!user) return null;

  return (
    <div className="author">
      <div className="author-image">
        <img src={user.avatar_urls[96]} alt={`${user.name} avatar`} />
      </div>
      <div className="about">
        <p className="name">{user.name}</p>
        <p className="description">{user.description}</p>
      </div>
    </div>
  )
}
