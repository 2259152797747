import { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import './Press.scss';


export default function Press({ data }) {
  const [pinnedPress, setPinnedPress] = useState([]);

  const carouselBreakpoints = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  }

  const separatePress = () => {
    const pinned = data.press_links.filter(post => post.featured_link=== true);

    if (!pinnedPress.length) {
      setPinnedPress(pinned)
    }
  }

  useEffect(() => {
    separatePress();
  }, [data])

  useEffect(() => {
    console.log(data);
  }, [data])

  return (
    <section className="page-content press">
      <div className="container">
        <div className="page-intro">
          <div className="in-the-press">
            <h5 dangerouslySetInnerHTML={{__html: data.in_the_press.headline}}></h5>
            <p dangerouslySetInnerHTML={{__html: data.in_the_press.body_copy}}></p>
          </div>
          <div className="pinned-display">
            <Carousel responsive={carouselBreakpoints} infinite={true}>
              {pinnedPress.map(link => (
                <a className="post" href={link.cta.url} key={link.title}>
                <div className="post-image">
                  <img src={link.image.url} alt={link.title} />
                </div>

                <div className="post-content">
                  <p className="post-title" dangerouslySetInnerHTML={{__html: link.title}}></p>
                  <a className="read-more">{link.cta.title}</a>
                </div>
              </a>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="default-display">
          {data.press_links.map(link => (
            <a className="post" href={link.cta.url} key={link.title}>
              <div className="post-image">
                <img src={link.image.url} alt={link.title} />
              </div>

              <div className="post-content">
                <p className="post-title" dangerouslySetInnerHTML={{__html: link.title}}></p>
                <a className="read-more">{link.cta.title}</a>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}
