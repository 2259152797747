import Hero from './Hero/Hero'
import About from './About/About'


export default function Component({ component, pageData }) {
  const components = {
    hero: Hero,
    about: About,
  }
  const Component = components[component.acf_fc_layout];

  if (Component) {
    return (
      <Component data={component} pageData={pageData} />
    )
  }

  return null;
}
