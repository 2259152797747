import { useEffect, useState } from 'react';
import { API_URL } from 'assets';
import './TitleCard.scss';


export default function TitleCard({ pageData, activeOption }) {
  const [activeData, setActiveData] = useState(null);

   useEffect(() => {
    // Extract the route value
    if (activeOption) {
      const activeRoute = pageData.acf.contact_options.filter(option => option.slug === activeOption);
      console.log(activeRoute[0]);
      setActiveData(activeRoute[0]);
    }
  }, [activeOption]);

  useEffect(() => {
    console.log("active", activeData);
  }, [activeData])

  return (
    <section className={`title-card ${activeData ? 'form-selected' : ''}`}>
      <h1>{activeData ? activeData.name : pageData.title.rendered }</h1>
      <p>{activeData ? activeData.summary : pageData.acf.page_summary}</p>
      <div className="image">
        <img src={activeData ? activeData.featured_image.url : pageData.acf.featured_image.url}/>
      </div>
    </section>
  )
}
