import { useEffect, useState } from 'react';
import './Hero.scss';


export default function Hero(component) {

  const { data } = component;

  if (!component) return null;

  return (
    <section className="team-hero">
      <div className="container">
        <h1>{data.header}</h1>
        <p dangerouslySetInnerHTML={{ __html: data.body_copy }}></p>
        {data.hero_image ?
          <img src={data.hero_image.url} alt="Arts Help Team Page hero image display" />
          : null
        }
      </div>
    </section>
  )
}
