import { Helmet } from 'react-helmet';
import './CCC.scss';

import Hero from './components/Hero/Hero'
import StepsCopy from './components/StepsCopy/StepsCopy'
import Education from './components/Education/Education'
import Pledge from './components/Pledge/Pledge'
import Grants from './components/Grants/Grants'
import JoinTheMovement from './components/JoinTheMovement/JoinTheMovement'
import ClimateChange from './components/ClimateChange/ClimateChange'
import BitcoinConsumes from './components/BitcoinConsumes/BitcoinConsumes'
import BitcoinConsumesMobile from './components/BitcoinConsumesMobile/BitcoinConsumesMobile'
import Solutions from './components/Solutions/Solutions'
import Quote from './components/Quote/Quote'
import Join from './components/Join/Join'

export default function CCC() {

  return (
    <>
      <Helmet bodyAttributes={{ className: 'page-ccc' }}>
        <title>Arts Help | Conscious Crypto Creator</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <Hero />
      <StepsCopy />
      <Education />
      <Pledge />
      <Grants />
      <JoinTheMovement />
      <ClimateChange />
      <BitcoinConsumes />
      <BitcoinConsumesMobile />
      <Solutions />
      <Quote />
      <Join />
    </>
  )
}
