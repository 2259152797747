import { useEffect, useState } from 'react'
import _ from 'lodash';
import { API_URL } from 'assets';
import './ArticlesGrid.scss'
import Post from 'components/Post/Post';

import DownArrow from 'img/arrow-down.svg';
import Loading from 'img/loading.svg';

export default function ArticlesGrid(series) {
  const content = series.series;
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    const getPosts = async () => {
      setIsLoadingMore(true);

      const postsResponse = await fetch(`${API_URL}/posts?series=${content.id}&per_page=6&page=${currentPage}&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const postsItems = await postsResponse.json();

      setTotalPages(postsResponse.headers.get('x-wp-totalpages'));
      setPosts(curr => [...curr, ...postsItems]);
      setIsLoadingMore(false);

      if (currentPage > 1) document.querySelector('.series-article-listing .post:last-child').scrollIntoView();
    }

    getPosts();
  }, [currentPage]);

  if (!posts) return null;

  return (
    <div className="series-article-grid">
      <div className="container">
        <div className="series-article-listing">
          {posts.map((post, index) => (
            <Post post={post} key={post.id + index} />
          ))}
        </div>
        {currentPage < totalPages ?
          <button onClick={() => setCurrentPage(curr => curr + 1)} disabled={isLoadingMore} className="load-more">
            {isLoadingMore ?
              <>
                LOADING...
                <img src={Loading} className="load-asset load-activity" />
              </>
              :
              <>
                LOAD MORE
                <img src={DownArrow} className="load-asset load-more-arrow" />

              </>
            }
          </button>
          : null
        }
      </div>
    </div>
  )
}
