import { useEffect, useState, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { OptionsContext } from 'OptionsContext';
import './Hero.scss'

export default function Hero({ data }) {
  const options = useContext(OptionsContext);

  return (
    <section className="hero">
      <div className="video-bg">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="fullscreen-video"
        >
          <source src={data.background_video.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="container">
        <div className="title">
          <h1>
              <img src={options.logo_primary.url} alt={options.logo_primary.alt} />
          </h1>
          <h2>{data.subtitle}</h2>
        </div>
      </div>
    </section>
  )
}
