import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { API_URL } from 'assets';
import './GetInvolved.scss';

import Lottie from 'lottie-react';
import Arrow from './assets/arrow-right.svg';
import LoadingAnimation from 'assets/loading-loop.json'
import TitleCard from './components/TitleCard/TitleCard';
import Partner from './pages/Partner/Partner';
import FeaturedArtist from './pages/FeaturedArtist/FeaturedArtist';
import ContributingWriter from './pages/ContributingWriter/ContributingWriter';
import Submission from './pages/Submission/Submission';

export default function GetInvolved() {
  const location = useLocation();
  const navigate = useNavigate();
  const [page, setPage] = useState(null);
  const [activeOption, setActiveOption] = useState(null);
  const [formIsSuccess, setFormIsSuccess] = useState(false);

  const selectOption = (route) => {
    navigate(`/getinvolved/${route}`);
  }

  const handleFormSubmit = (success) => {
    setFormIsSuccess(success);
  };

  useEffect(() => {
    const getPage = async () => {
      const pageResponse = await fetch(`${API_URL}/pages/782?x=1`);
      const pageData = await pageResponse.json();
      setPage(pageData);
    }

    getPage();
  }, []);

  useEffect(() => {
    // Extract the route value
    const routeValue = location.pathname.split('/').pop();
    console.log("test: ", routeValue);
    setActiveOption(routeValue);
  }, [location.pathname]);

  useEffect(() => {
    console.log(page);
  }, [page])



  if (!page) return (
      <div className="container">
        <Lottie animationData={LoadingAnimation} loop={true} className="loading-animation"/>
      </div>
  );

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page get-involved' }}>
        <title>Arts Help | Get Involved</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <div className="get-involved-container">
        {formIsSuccess ?
          <Submission />
          :
          <>
            <TitleCard pageData={page} activeOption={activeOption} />
            <div className={`get-involved-content ${!activeOption || activeOption === "getinvolved" ? "option-section" : "form"}`}>
              { activeOption === 'partner' ? (
                  <Partner onFormSubmit={handleFormSubmit}/>
                ) : activeOption === 'contributing-writer' ? (
                  <ContributingWriter onFormSubmit={handleFormSubmit}/>
                ) : activeOption === 'featured-artist' ? (
                  <FeaturedArtist onFormSubmit={handleFormSubmit}/>
                ) : (
                  <>
                    {page.acf.contact_options.map(option => (
                      <div key={option.name} onClick={() => selectOption(option.slug)} className="contact-option">
                        <div className="option-details">
                          <div className="title">
                            <h3>{option.name}</h3>
                            <div className="button">
                              <img src={Arrow} />
                            </div>
                          </div>
                          <p className="description">{option.description}</p>
                        </div>
                      </div>
                    ))}
                  </>
              )}
            </div>
          </>
        }
      </div>
    </>
  )
}
