import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from 'assets';
import './Hero.scss';
import Post from 'components/Post/Post';
import { ColorChecker } from 'components/ColorChecker.js'

export default function Hero({ category, categoryName, categoryColor, featuredArticle }) {
  const [posts, setPosts] = useState([]);
  const [featuredPost, setFeaturedPost] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      const response = await fetch(`${API_URL}/posts?categories=${category}&per_page=${featuredArticle ? 3 : 4}&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const data = await response.json();

      if (!featuredArticle) {
        setPosts(data.slice(1, data.length));
        setFeaturedPost(data[0]);
      } else {
        setPosts(data);
      }
    }
    const getFeaturedArticle = async () => {
      const response = await fetch(`${API_URL}/posts/${featuredArticle[0]}?_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const data = await response.json();

      setFeaturedPost(data);
    }

    getPosts();
    if (featuredArticle) getFeaturedArticle();
  }, [category, featuredArticle]);

  if (!posts.length) return null;

  return (
    <section className="category-hero" style={{'--colorScheme': categoryColor, '--textColor': ColorChecker(categoryColor) ? '#fff' : '#000'}}>
      <h1><span>{categoryName}</span> <span>+ Art</span></h1>

      <Post post={featuredPost}/>

      <div className="latest-news">
        <p>Latest News</p>
        <ul>
          {posts.map(post => (
            <li key={post.id}>
              <Link to={`/editorial/${post.slug}`} dangerouslySetInnerHTML={{ __html: post.title.rendered }}></Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
