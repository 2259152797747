import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { API_URL } from 'assets';
import './Editorial.scss';

import Lottie from 'lottie-react';
import LoadingAnimation from 'assets/loading-loop.json'

import Component from './components/Component'
import CategoriesNav from './components/CategoriesNav/CategoriesNav'

export default function Editorial() {
  const [page, setPage] = useState(null);

  useEffect(() => {
    const getPage = async () => {
      const pageResponse = await fetch(`${API_URL}/pages/65`);
      const pageData = await pageResponse.json();
      setPage(pageData);
    }

    getPage();
  }, []);


  if (!page) return (
      <div className="container">
        <Lottie animationData={LoadingAnimation} loop={true} className="loading-animation"/>
      </div>
  );

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page-editorial' }}>
        <title>Arts Help | Editorial</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <CategoriesNav />
      {page.acf.editorial_components.map((component, index) => <Component component={component} key={index}/>)}
    </>
  )
}
