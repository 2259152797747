import { useEffect, useState, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom';
import './GetInvolved.scss'


export default function GetInvolved({data}) {

  return (
    <section className="get-involved-section">
      <div className="bg-image">
        <img src={data.background_image.url} />
      </div>
      <img src={data.section_image.url} className="section-image" />
      <div className="container">
        <div className="section-content">
          <h2 dangerouslySetInnerHTML={{ __html: data.header }}></h2>
          <p dangerouslySetInnerHTML={{ __html: data.body_copy }}></p>
          <a href={data.cta.url} className="btn">{data.cta.title}</a>
        </div>
      </div>
    </section>
  )
}
