import { useEffect, useState } from 'react'
import { API_URL } from 'assets';
import _ from 'lodash';
import './Posts.scss'

import Post from 'components/Post/Post';
import DownArrow from 'img/arrow-down.svg';
import Loading from 'img/loading.svg';

export default function Posts({ tag }) {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);


  useEffect(() => {
    setCurrentPage(1);
    const getInitialPosts = async () => {
      const postsResponse = await fetch(`${API_URL}/posts?categories=${tag.id}&per_page=5&page=1&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const postsItems = await postsResponse.json();
      setTotalPages(postsResponse.headers.get('x-wp-totalpages'));
      setPosts(postsItems);
    }

    getInitialPosts();
  }, [tag.id]);

  useEffect(() => {

    const getMorePosts = async () => {
      setIsLoadingMore(true);

      const postsResponse = await fetch(`${API_URL}/posts?categories=${tag.id}&per_page=5&page=${currentPage}&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const postsItems = await postsResponse.json();

      setPosts(curr => [...curr, ...postsItems]);
      setIsLoadingMore(false);

      if (currentPage > 1) document.querySelector('.tag-article-listing .post:last-child').scrollIntoView();
    }

    if (currentPage > 1) {
      getMorePosts();
    }
  }, [currentPage])

  useEffect(() => {
    if(currentPage === 1) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [tag.id, currentPage])

  if (!posts) return null;

  return (
    <section className="tag-articles">
      <div className="container">
        <div className="page-intro">
          <h1>{tag.name}</h1>
        </div>

        <div className="tag-article-listing">
          {posts.map(post => (
            <Post post={post} key={post.id} />
          ))}
        </div>

        {currentPage < totalPages ?
          <button onClick={() => setCurrentPage(currentPage + 1)} disabled={isLoadingMore} className="load-more" aria-label="Load more articles">
            {isLoadingMore ?
              <>
                LOADING...
                <img src={Loading} className="load-asset load-activity" />
              </>
              :
              <>
                LOAD MORE
                <img src={DownArrow} className="load-asset load-more-arrow" />
              </>
            }
          </button>
          : null
        }
      </div>
    </section>
  )
}
