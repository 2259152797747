import { useState, useEffect } from 'react';
import { API_URL } from 'assets';
import './FeaturedArticles.scss';

import Post from 'components/Post/Post';

export default function FeaturedArticles({ data }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const postsResponse = await fetch(`${API_URL}/posts?acf_featured=1&per_page=5&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const postsItems = await postsResponse.json();

      // Sort post with ID matching prominent_article first
      postsItems.sort((a, b) => {
        if (a.id === data.prominent_article) return -1;
        if (b.id === data.prominent_article) return 1;
        return 0;
      });

      setPosts(postsItems);
    }

    getPosts();
  }, [data.prominent_article])

  if (!posts.length) return null;

  return (
    <section className="featured-articles">
      <h2 className="featured-articles-title">Featured Articles</h2>

      <div className="posts">
        {posts.map(post => (
          <Post post={post} key={post.id} />
        ))}
      </div>
    </section>
  )
}
