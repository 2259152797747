import { useEffect } from 'react'
import './Join.scss';

import Twitter from './../../assets/ccc-twitter.svg';
import Tiktok from './../../assets/ccc-tiktok.svg';
import Linkedin from './../../assets/ccc-linkedin.svg';
import Instagram from './../../assets/ccc-instagram.svg';

export default function Join() {

  const scrollToTop = () => {
    document.getElementById('root').scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <section className="ccc-sign" id="join" data-progress="join">
      <div className="container">
        <div className="ccc-cols">
          <div className="ccc-col ccc-form-container">
            <h2>Join the Conscious Crypto Creator movement</h2>
            <a href="/signup" className="trc-button">Sign up</a>
          </div>

          <div className="ccc-col">
            <h2>Share CCC with a<br /> friend!</h2>
            <ul className="ccc-social-links">
              <li><a href="https://twitter.com/artshelpnet" target="_blank" aria-label="Arts Help Twitter account"><img src={Twitter} alt="CCC Twitter" /></a></li>
              <li><a href="https://www.tiktok.com/@arts_help" target="_blank" aria-label="Arts Help TikTok account"><img src={Tiktok} alt="CCC Tiktok" /></a></li>
              <li><a href="https://www.linkedin.com/company/artshelp/" target="_blank" aria-label="Arts Help LinkedIn account"><img src={Linkedin} alt="CCC LinkedIn" /></a></li>
              <li><a href="https://www.instagram.com/arts_help/" target="_blank" aria-label="Arts Help Instagram account"><img src={Instagram} alt="CCC Instagram" /></a></li>
            </ul>
          </div>
        </div>
      </div>

      <button className="ccc-btt" onClick={scrollToTop}>Back to top</button>
    </section>
  )
}
