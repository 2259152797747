import { useEffect, useState } from 'react';
import { API_URL } from 'assets';
import './Partner.scss';

import Check from './../../assets/check.svg';


export default function Partner({onFormSubmit}) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    step1: { name: '', email: '', location: '', phone: '' },
    step2: { field1: [], field2: ''},
    step3: { field1: '', field2: false, field3: false },
  });
  const [stepValidity, setStepValidity] = useState({
    step1: false,
    step2: false,
    step3: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const currentStepData = { ...formData[`step${step}`] };

    if (step === 2 && type === 'checkbox') {

      let selectedOptions = [...currentStepData[name]];

      if (checked) {
        selectedOptions.push(value);
      } else {
        selectedOptions = selectedOptions.filter((option) => option !== value);
      }

      currentStepData[name] = selectedOptions;
    } else if (step === 3 && type === 'checkbox') {
      currentStepData[name] = type === 'checkbox' ? checked : value;
    } else {
      currentStepData[name] = value;
    }

    setFormData({ ...formData, [`step${step}`]: currentStepData });
  };

  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    onFormSubmit(true);
  };

  const handleValidation = () => {
    const currentStepData = { ...formData[`step${step}`] };
    let isValid = true;

    // Check if any required fields in the current step are empty
    for (const key in currentStepData) {
      if (currentStepData.hasOwnProperty(key)) {
        if (
          typeof currentStepData[key] === 'string' &&
          currentStepData[key].trim() === '' &&
          document.getElementsByName(key)[0].hasAttribute('required')
        ) {
          isValid = false;
          break;
        }
        if (
          Array.isArray(currentStepData[key]) &&
          currentStepData[key].length === 0 &&
          document.getElementsByName(key)[0].hasAttribute('required')
        ) {
          isValid = false;
          break;
        }
      }
    }

    setStepValidity({ ...stepValidity, [`step${step}`]: isValid });
  };


  useEffect(() => {
    handleValidation();
  }, [step, formData]);

  return (
    <div className="partner-form form-content">
      <div className="progress-bar">
        <div className="progress progress-1">
          <div className={`display ${step >= 1 ? 'active' : ''}`}>1</div>
          <span>Contact info</span>
        </div>
        <div className="progress progress-2">
          <div className={`display ${step >= 2 ? 'active' : ''}`}>2</div>
          <span>Partnership type</span>
        </div>
        <div className="progress progress-3">
          <div className={`display ${step >= 3 ? 'active' : ''}`}>3</div>
          <span>Additional info</span>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="step step-1">
            <div className="step-title">
              <h2>Let's start with the basics</h2>
              <p>* Indicates a required field</p>
            </div>
            <input
              type="text"
              name="name"
              value={formData.step1.name}
              placeholder="First and last name *"
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="email"
              value={formData.step1.email}
              placeholder="Email *"
              onChange={handleChange}
              required
            />
            <input
              type="text"
              name="location"
              value={formData.step1.location}
              placeholder="Location (Country, State, or City) *"
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="phone"
              value={formData.step1.phone}
              placeholder="Phone number "
              onChange={handleChange}
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
            />
          </div>
        )}
        {step === 2 && (
          <div className="step step-2">
            <div className="step-title">
              <h2>What kinds of partnership are you interested in?</h2>
              <p>* Indicates a required field</p>
            </div>
            <div className="select-applicable">
              <p>Select all that apply. *</p>
              <div className="selections">
                <label className={`${formData.step2.field1.includes('Marketing') ? 'checked' : ''}`}>
                  <input
                    type="checkbox"
                    name="field1"
                    value="Marketing"
                    checked={formData.step2.field1.includes('Marketing')}
                    onChange={handleChange}
                  />
                  Marketing
                </label>
                <label className={`${formData.step2.field1.includes('Donation') ? 'checked' : ''}`}>
                  <input
                    type="checkbox"
                    name="field1"
                    value="Donation"
                    checked={formData.step2.field1.includes('Donation')}
                    onChange={handleChange}
                  />
                  Donation
                </label>
                <label className={`${formData.step2.field1.includes('Volunteer') ? 'checked' : ''}`}>
                  <input
                    type="checkbox"
                    name="field1"
                    value="Volunteer"
                    checked={formData.step2.field1.includes('Volunteer')}
                    onChange={handleChange}
                  />
                  Volunteer
                </label>
                <label className={`${formData.step2.field1.includes('Other') ? 'checked' : ''}`}>
                  <input
                    type="checkbox"
                    name="field1"
                    value="Other"
                    checked={formData.step2.field1.includes('Other')}
                    onChange={handleChange}
                  />
                  Other
                </label>
              </div>
            </div>
            <textarea
              type="textarea"
              name="field2"
              value={formData.step2.field2}
              placeholder="Tell us more about your ideas, ways you want to work together, etc."
              onChange={handleChange}
            >
            </textarea>
          </div>
        )}
        {step === 3 && (
          <div className="step step-3">
            <div className="step-title">
              <h2>Is there anything else you'd like us to know?</h2>
              <p>* Indicates a required field</p>
            </div>
            <textarea
              type="textarea"
              name="field1"
              value={formData.step3.field1}
              placeholder="Tell us more about your ideas, why you want to work together, etc."
              onChange={handleChange}
            ></textarea>
            <div className="acknowledgements">
            <div className="acknowledgement">
              <label>
                <input
                  type="checkbox"
                  name="field2"
                  checked={formData.step3.field2}
                  onChange={handleChange}
                  required
                  />
                <div className={`custom-box ${formData.step3.field2 ? 'checked' : ''}`}><img src={Check} className="check"/></div>
                I agree to the terms and conditions
              </label>
            </div>
            <div className="acknowledgement">
              <label>
                <input
                  type="checkbox"
                  name="field3"
                  checked={formData.step3.field3}
                  onChange={handleChange}
                />
                  <div className={`custom-box ${formData.step3.field3 ? 'checked' : ''}`}><img src={Check} className="check"/></div>
                Subscribe to newsletter
              </label>
              </div>
            </div>
          </div>
        )}
        <div className="form-nav">
          <button type="button" onClick={handlePrevious}>
            Back
          </button>
          {step < 3 && (
            <button type="button" onClick={handleNext} disabled={!stepValidity[`step${step}`]}>
              Next
            </button>
            )}
            {step === 3 && (
              <button type="submit">Submit</button>
            )}
        </div>
      </form>
    </div>
  )
}
