import { useEffect, useState, useRef } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { API_URL } from 'assets';
import './Sdgs.scss'

import Lottie from 'lottie-react';
import LoadingAnimation from 'assets/loading-loop.json'

import Component from './components/Component'
import Intro from './components/Intro/Intro'
import SdgList from './components/SdgList/SdgList'

import SdgTitleBg from './assets/sdg-title-bg-mobile.png';
import SdgTitleBgArrow from './assets/sdg-title-bg-caret.svg';


export default function Sdgs({ children }) {

  const [isMobile, setIsMobile] = useState(false);
  const [page, setPage] = useState(null);
  const [displayContent, setDisplayContent] = useState(null);
  const [tags, setTags] = useState(null);

  const [activeTag, setActiveTag] = useState(0);

  const [bgIsFixed, setBgIsFixed] = useState(false);
  const sdgRef = useRef(null);

  const [listBgIsFixed, setListBgIsFixed] = useState(false);
  const [listBgBottom, setListBgBottom] = useState(false);
  const listRef = useRef(null);

  const [targetDivActive, setTargetDivActive] = useState(false);

  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);

  const [scrollY, setScrollY] = useState(0);

  const getBackgroundImage = () => {
    const contentObject = page.acf.components.filter(obj => obj.acf_fc_layout === 'intro');
    setDisplayContent(...contentObject);
  }

  const getTags = async () => {
    const response = await fetch(`${API_URL}/categories?per_page=99`);
    const data = await response.json();

    const sdgTags = data.filter(tag => tag.acf.type === "SDG Tag")

    sdgTags.sort((a, b) => a.acf.sdg_list_display_content.order - b.acf.sdg_list_display_content.order);

    setTags(sdgTags);
  }

  const scrollToSdg = (sdg) => {

    const sdgElement = document.getElementById("sdg" + sdg);
    const sdgNavItem = document.getElementById("sdgNavItem" + sdg);
    if (sdgElement) {
        const activeElements = document.querySelectorAll('.active');
        activeElements.forEach(element => {
            element.classList.remove('active');
        });

        const activeNavItems = document.querySelectorAll('.active-tag');
        activeNavItems.forEach(element => {
            element.classList.remove('active-tag');
        });

        sdgElement.scrollIntoView({ behavior: 'smooth' });
        sdgElement.classList.add('active');

        sdgNavItem.classList.add('active-tag');
    }

    setActiveTag(sdg);
  };

  const toggleDropdown = () => {
    setIsMobileDropdownOpen(prevState => !prevState);
  };

  useEffect(() => {
    const getPage = async () => {
      const pageResponse = await fetch(`${API_URL}/pages/1008?v=20`);
      const pageData = await pageResponse.json();
      setPage(pageData);
    }

    getPage();
  }, []);

  useEffect(() => {
    if (page) {
      getBackgroundImage()
    };
  }, [page])

  useEffect(() => {
    getTags();
  }, [])

  useEffect(() => {
    console.log(tags);
  }, [tags])

  //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////

  useEffect(() => {
    const handleScroll = () => {
      const element = sdgRef.current;
      if (element) {
        const { top } = element.getBoundingClientRect();
        setBgIsFixed(top <= 0);
      }
    };

    if (isMobile) {
      window.addEventListener('touchstart', handleScroll);
      window.addEventListener('touchmove', handleScroll);
    } else {
      window.addEventListener('wheel', handleScroll);
    }

    return () => {
      if (isMobile) {
        window.addEventListener('touchstart', handleScroll);
        window.addEventListener('touchmove', handleScroll);
      } else {
        window.addEventListener('wheel', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const element = listRef.current;
      const elementRect = listRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      if (element) {
        const { top } = element.getBoundingClientRect();
        setListBgIsFixed(top <= 0);
        setListBgBottom(top <= (0 -( elementRect.height - windowHeight)));
      }


    };

    if (isMobile) {
      window.addEventListener('touchstart', handleScroll);
      window.addEventListener('touchmove', handleScroll);
    } else {
      window.addEventListener('wheel', handleScroll);
    }

    return () => {
      if (isMobile) {
        window.addEventListener('touchstart', handleScroll);
        window.addEventListener('touchmove', handleScroll);
      } else {
        window.addEventListener('wheel', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (listBgIsFixed && !listBgBottom) {
      document.body.style.scrollSnapType = 'y mandatory';
      document.body.style.height = '100vh';
    } else {
      document.body.style.scrollSnapType = null;
      document.body.style.height = '100vh';
    }
  }, [listBgIsFixed, listBgBottom])


  useEffect(() => {
    const handleScroll = (e) => {

      if (e.deltaY < 0) {
        setScrollY('up');
      } else if (e.deltaY > 0) {
        setScrollY('down');
      }

      // const scrollContainer = listRef.current;
      // if (!scrollContainer) return;

      // const { top } = scrollContainer.getBoundingClientRect();
      // const containerTop = top + window.pageYOffset;

      //    // Check if ".scroll-container" reaches the top of the window
      // if (top <= 0) {
      //   const tags = document.querySelectorAll('.tag');
      //   let nearestTagIndex = 0;
      //   let minDistance = Infinity;
      //   // Find the nearest ".tag" div
      //   tags.forEach((tag, index) => {
      //     const { top } = tag.getBoundingClientRect();
      //     const distance = Math.abs(top);
      //     if (distance < minDistance) {
      //       minDistance = distance;
      //       nearestTagIndex = index;
      //     }
      //   });

      //   if (activeTag !== nearestTagIndex) {
      //     setActiveTag(nearestTagIndex);
      //   }
      // }

    };

    //Different solution needed for mobile
    if (!isMobile) {
      window.addEventListener('wheel', handleScroll);
    } else {
      window.addEventListener('touchmove', handleScroll);
    }

    return () => {
      //Different solution needed for mobile
      if (!isMobile) {
        window.addEventListener('wheel', handleScroll);
      } else {
        window.addEventListener('touchmove', handleScroll);
      }
    };
  }, [activeTag]);


  useEffect(() => {
    if (tags) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // Adjust this threshold as needed
      };

      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // If the top edge of the element is visible on the screen
            const activeIndex = entry.target.id.split("sdg")[1];
            if (activeIndex <= 17) {
              console.log(parseInt(activeIndex));
              setActiveTag(parseInt(activeIndex));
            }
          }
        });
      }, options);

      // Observe each tag element by its ID
      tags.forEach((_, index) => {
        const element = document.getElementById(`sdg${index + 1}`);
        if (element) {
          observer.observe(element);
        }
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [tags]);



  //////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////


  useEffect(() => {
    const handleScroll = () => {
      const targetDiv = document.querySelector('.scroll-container');
      if (targetDiv) {
        const targetDivRect = targetDiv.getBoundingClientRect();
        const topOfTargetDiv = targetDivRect.top;
        const bottomOfTargetDiv = targetDivRect.bottom;
        const windowHeight = window.innerHeight;

        if (topOfTargetDiv <= 0 && bottomOfTargetDiv >= windowHeight) {
          setTargetDivActive(true);
        } else {
          setTargetDivActive(false);
        }
      }
    };

    if (isMobile) {
      window.addEventListener('touchstart', handleScroll);
      window.addEventListener('touchmove', handleScroll);
    } else {
      window.addEventListener('wheel', handleScroll);
    }

    // Cleanup
    return () => {
      if (isMobile) {
        window.addEventListener('touchstart', handleScroll);
        window.addEventListener('touchmove', handleScroll);
      } else {
        window.addEventListener('wheel', handleScroll);
      }
    };
  }, [displayContent]);


  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobileDevice = /mobile|android|iphone|ipad|phone/i.test(userAgent);
    setIsMobile(isMobileDevice);
  }, []);


  if (!page || !tags) return (
    <div className="container">
      <Lottie animationData={LoadingAnimation} loop={true} className="loading-animation"/>
    </div>
);

  return (
    <div className="page sdgs">
      <Helmet>
        <title>SDGs</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      {page.acf.components.map((component, index) => <Component component={component} key={index} />)}

      {displayContent ?
        <div className={`sdg-display ${listBgIsFixed ? 'fixed' : ''} ${listBgBottom ? 'list-end' : ''}`} ref={sdgRef}>
          <div className={`sdg-display-bg ${bgIsFixed ? 'fixed' : ''} ${listBgBottom ? 'list-end' : ''}`}>
            <img src={displayContent.background_image.url} />
          </div>
          <div className="container">

            <Intro content={displayContent} />

            <div className="scroll-container" ref={listRef}>
              <div className={`list-background ${listBgIsFixed ? 'fixed' : ''} ${listBgBottom ? 'list-end' : ''}`}></div>

               {/* SDG Nav -- for both desktop and mobile */}
                {!isMobile ?
                  <div className={`tag-nav desktop ${targetDivActive ? 'active-tag-nav' : ''}`}>
                    {tags.map((tag, index) => (
                      <div className={`tag-nav-item tag-nav-item-${index + 1} ${index + 1 == activeTag ? 'active-tag' : ''}`} id={`sdgNavItem${index + 1}`} onClick={() => scrollToSdg(`${index + 1}`)} key={tag.name}>
                        {index >= 9 ?
                          <>
                            {index + 1}
                          </>
                          :
                          <>
                            0{index + 1}
                          </>
                        }
                      </div>
                    ))}
                  </div>
                : null
              }
              <div className={`tag-nav mobile ${targetDivActive ? 'active-tag-nav' : ''}`}>
                <div className="nav-display" onClick={toggleDropdown} style={{ backgroundImage: `url(${SdgTitleBg})` }}>
                  {activeTag > 0 ?
                    <>
                      <span>
                        {activeTag >= 9 ?
                          <>
                            {activeTag}
                          </>
                          :
                          <>
                            0{activeTag}
                          </>
                        }
                      </span>
                      <h3 className={`${tags[activeTag - 1].name.length > 14 ? "long" : ""}`} dangerouslySetInnerHTML={{__html: tags[activeTag -1].name}}></h3>
                    </>
                    :
                    <>
                      <span>01</span>
                      <h3>No Poverty</h3>
                    </>
                    }
                    <button className={`nav-toggle ${isMobileDropdownOpen ? 'open' : ''}`}>
                      <img src={SdgTitleBgArrow} />
                    </button>
                  </div>
                  <div className={`mobile-nav-dropdown ${isMobileDropdownOpen ? 'open' : ''}`}>
                    {tags.map((tag, index) => (
                      <div className={`tag-nav-item tag-nav-item-${index + 1} ${index === activeTag ? 'active-tag' : ''}`} id={`sdgNavItem${index + 1}`} onClick={() => { scrollToSdg(index + 1); toggleDropdown(); }} key={tag.name}>
                        <span>
                          {index >= 9 ?
                            <>
                              {index + 1}
                            </>
                            :
                            <>
                              0{index + 1}
                            </>
                          }
                        </span>
                        {tag.name}
                      </div>
                    ))}
                  </div>
              </div>

              {/* SDGs list */}
              {tags.map((tag, index) => {
                return (
                  <div className={`tag tag-${index + 1} ${index + 1  == activeTag ? 'active' : ''} ${scrollY}`} key={tag.name} id={`sdg${index + 1}`}>
                    <SdgList data={tag} index={index} />
                  </div>
                )

              })}

            </div>
          </div>
        </div>
        : null
      }

    </div>
  )
}
