import { useState, useEffect } from 'react'
import './Callout.scss'
import bullhorn from './assets/revolution-still-life-design.png'

export default function Callout(component) {

  const [content, setContent] = useState(component.data);

  return (
    <>
      <section className="editorial-callout call-for-submissions" style={{ backgroundImage: `url(${content.background_image})`}}>
        <div className="container">
          <img src={bullhorn} className="bullhorn" />
          <div className="content">
            <div className="headline">
              <h2>{content.headline}</h2>
            </div>
            <div className="body">
              <p>{content.description}</p>
              <div className="cta">
                <button>{content.cta.title}</button>
              </div>
            </div>
         </div>
        </div>
      </section>
    </>
  )
}
