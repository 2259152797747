import Content from './Content/Content';
import Gallery from './Gallery/Gallery';
import Excerpt from './Excerpt/Excerpt';

export default function PostComponent({ component }) {
  const components = {
    content: Content,
    gallery: Gallery,
    excerpt: Excerpt,
  }
  const Component = components[component.acf_fc_layout];

  return (
    <Component data={component} />
  )
}
