import './JoinTheMovement.scss';


export default function JoinTheMovement() {

  return (
    <section className="ccc-join">
      <div className="container">
        <h2>Join the movement.</h2>
        <a href="/signup" className="trc-button cyan">Sign up for updates</a>
      </div>
    </section>
  )
}
