import { useEffect, useState, useContext } from 'react'
import { API_URL } from 'assets';
import { Link, NavLink } from 'react-router-dom';
import './Subscribe.scss'


export default function Subscribe({ data }) {
  const [inputData, setInputData] = useState('');

  const handleChange = (e) => {
    setInputData(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <section className="subscribe">
      <div className="container">
        <div className="image">
          <img src={data.section_image.url} alt="Subscribe to the Arts Help newsletter"/>
        </div>
        <div className="message">
          <p>{data.body_copy}</p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="email"
              value={inputData}
              placeholder="EMAIL"
              onChange={handleChange}
              required
            />
            <button type="submit" className="view-more white">{data.submit_message}</button>
          </form>
        </div>
      </div>
    </section>
  )
}
