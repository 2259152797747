import React, { useEffect, useState } from 'react';
import './Advisory.scss';


export default function Advisory({ data }) {

  if (!data) return null;

  return (
    <section className="advisory">
      <div className="container">
        <div className="intro">
          <h2>{data.headline}</h2>
          <p>{data.body_copy}</p>
        </div>
        <div className="advisory-board">
          {data.advisory_board.map(advisor => (
            <div className="advisor" key={advisor.name}>
              <p className="name" dangerouslySetInnerHTML={{__html: advisor.name}}></p>
              <p className="title" dangerouslySetInnerHTML={{__html: advisor.title}}></p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
