import { useEffect } from 'react'
import './Grants.scss';
import Rellax from 'rellax';

import GrantGraphic from './../../assets/ccc-verified.png';

export default function Grants() {
  const Graphic = () => {
    const rellaxInit = () => {
      new Rellax('.ccc-grants-bg', {
        vertical: true,
        center: true,
      });
    }

    useEffect(() => {
      rellaxInit();
    }, []);

    return (
        <img
          src={GrantGraphic}
          alt="Image of artists painting. The text says: CCC verified artist."
          className="parallax-layer rellax ccc-grants-bg"
          data-rellax-vertical-scroll-axis="xy"
          data-rellax-vertical-speed="2.1"
          data-rellax-percentage="0.5"
        />
    )
  }

  return (
    <section className="ccc-grants">
    <div className="container">
      <div className="ccc-cols">
        <div className="ccc-col">
          <h2>3. Become a CCC Verified Artist and apply for an Artist Grant</h2>
          <p>With a <strong>$3.5M Artist Grant</strong> for CCC Verified Artists, we&rsquo;re empowering artists to use their art in the fight for transparency and sustainable crypto-validation practices.</p>
        </div>
        <div className="ccc-col">
        </div>
      </div>
    </div>

    <Graphic/>

  </section>
  )
}
