import { useState, useEffect } from 'react';
import './Gallery.scss'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Arrow from './arrow.svg'


export default function Gallery({ data }) {

  const ButtonGroup = ({ next, previous, goToSlide, ...rest  }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="carousel-button-group">
        <button className="carousel-button left" onClick={() => previous()}>
          <img src={Arrow} alt="arrow" />
        </button>
        <button className="carousel-button right" onClick={() => next()}>
          <img src={Arrow} alt="arrow" />
        </button>
      </div>
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1124 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1124, min: 810 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 810, min: 0 },
      items: 1
    },
  };

  return (
    <div className="gallery">
      <Carousel responsive={responsive} infinite={true} arrows={false} centerMode={true} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />} focusOnSelect={true} rewindWithAnimation={true}>
        {data.images.map((image, index) => (
          <div className="gallery-image" key={index}>
            <div className="draggable"></div>
              <img src={image.url} alt={image.alt}/>
            <span className="caption">{image.caption}</span>
          </div>
        ))}
      </Carousel>
    </div>
  )
}
