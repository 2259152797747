import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from 'assets';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

export default function Post({ post }) {
  const history = useNavigate();
  const [coAuthors, setCoAuthors] = useState([]);
  const primaryTag = post && post._embedded['wp:term'] ? _.flatten(post._embedded['wp:term']).filter(term => term._links.up)[0] : null;


  const tagLink = (e, link) => {
    e.preventDefault();
    history(link);
  };

  useEffect(() => {
    const getAuthors = async () => {
      const response = await fetch(`${API_URL}/users?include=${_.uniq(post.acf.co_authors).join(',')}`);
      const data = await response.json();
      setCoAuthors(data);
    }

    if (post.acf.co_authors) getAuthors();
  }, [post.acf.co_authors])


  return (
    <Link className="post" to={`/editorial/${post.slug}`}>
      <div className="post-image">

        <img src={!post.acf.featured_images[0].image ? post.acf.featured_images[0].url : post.acf.featured_images[0].image} alt={post.acf.featured_images[0].image.title} />

        {/* { primaryTag &&  <Link className="post-tag" to={`/editorial/tag/${primaryTag.name.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-')}`} style={{ '--colorScheme' : primaryTag.acf.color}}><span dangerouslySetInnerHTML={{ __html: primaryTag.name }}></span></Link> } */}
        {primaryTag &&
          <div
            className="post-tag"
            onClick={(e) => tagLink(e, `/editorial/tag/${primaryTag.name.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-')}`)}
            style={{ '--colorScheme': primaryTag.acf.color }}>
            <span dangerouslySetInnerHTML={{ __html: primaryTag.name }}></span>
          </div>
        }
      </div>

      <div className="post-content">
        {primaryTag && <span className="post-tag" dangerouslySetInnerHTML={{__html: primaryTag.name}}></span>}
        <p className="post-title" dangerouslySetInnerHTML={{__html: post.title.rendered}}></p>

        <p className="post-author">
          <span>By </span>
          <span>{post._embedded.author[0].acf.display_name}</span>
          {coAuthors.map(author => (
            <span key={author.id}>{author.acf.display_name}</span>
          ))}
        </p>

        <div className="post-excerpt" dangerouslySetInnerHTML={{__html: post.excerpt.rendered.length > 170 ? post.excerpt.rendered.slice(0, 170) + "..." : post.excerpt.rendered}}></div>
        <p className="read-more">Read more &gt;</p>
      </div>
    </Link>
  )
}
