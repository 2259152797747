import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from 'assets';
import './TagsBrowser.scss';
import { ColorChecker } from 'components/ColorChecker.js'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function TagsBrowser() {
  const [tags, setTags] = useState([]);
  const carouselBreakpoints = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1124 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1124, min: 810 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 810, min: 0 },
      items: 1,
    },
  }

  useEffect(() => {
    const getTags = async () => {
      const response = await fetch(`${API_URL}/categories?per_page=99&_fields=id,count,name,slug,parent,acf&exclude=1`);
      let data = await response.json();

      data = data.filter(item => item.parent !== 0);
      data.sort((a, b) => {
        if (a.count > b.count) return -1;
        if (a.count < b.count) return 1;
        return 0;
      });

      setTags(data);
    }

    getTags();
  }, []);

  if (!tags.length) return null;

  return (
    <section className="tags-browser">
      <h2 className="tags-browser-intro"><span>Browse articles by</span> <span>Sustainable Development Goal</span></h2>

      <Carousel responsive={carouselBreakpoints} centerMode={true}>
        {tags.map(tag => (
          <Link className="tags-browser-tag" to={`/editorial/tag/${tag.slug}`} key={tag.id} style={{backgroundImage: `url(${tag.acf.featured_image})`, '--colorScheme': tag.acf.color, '--textColor': ColorChecker(tag.acf.color) ? '#fff' : '#000'}}><span dangerouslySetInnerHTML={{__html: tag.name}}></span></Link>
        ))}
      </Carousel>

      <a className="btn" href="/editorial/tag/"><span>Learn more about SDG</span>s</a>
    </section>
  )
}
