import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import './GetInvolvedCallout.scss';


export default function GetInvolvedCallout({ data }) {

  const [ref, inView] = useInView({
    triggerOnce: false, // Only trigger once when the element comes into view
  });

  const [elements, setElements] = useState([]);

  const marqueeContent = (
    <div className="marquee" ref={ref}>
      <span className="partner red">Become a partner</span>
      <span className="support">Support Arts Help</span>
    </div>
  );

  const cloneAndAnimate = () => {
    console.log("test");
    setElements((prevElements) => [...prevElements, marqueeContent]);
  };


  const removeElement = (index) => {
    setElements((prevElements) => {
      const updatedElements = [...prevElements];
      updatedElements.splice(index, 1);
      return updatedElements;
    });
  };

  useEffect(() => {
    // if (inView) {
    //   cloneAndAnimate();
    // }
    // else if (elements.length > 0) {
    //   console.log("test2");
    //   removeElement(0);
    // }

  setTimeout(() => {
    cloneAndAnimate();
    if (elements.length > 2) {
      removeElement(0);
    }
  }, 15000)
  }, [inView]);


  // const numberOfRepeats = 600;

  useEffect(() => {
    console.log(data);
  }, [data])

  if (!data) return null;

  return (
    <section className="get-involved-callout">

      <div className="marquee-container">
        {/* <div className="marquee" ref={ref}>
            <span className="partner">Become a partner</span>
            <span className="support">Support Arts Help</span>
        </div> */}

        {/* {elements.map((element, index) => (
                // <
                //   className="marquee"
                //   key={index}
                //   style={{
                //     whiteSpace: 'nowrap',
                //     animation: 'marquee 60s linear infinite', // Same animation as original
                //     animationTimingFunction: 'linear',
                //   }}
                // >
                <React.Fragment key={index}>
                  {element}
                </React.Fragment>
              ))} */}
              {/* {inView && cloneAndAnimate()} */}
      </div>

      <div className="container">
        <div className="background-image">
          <img src={data.background_image.url} alt="Get Involved" />
        </div>
        <div className="content">
          <div className="cta">
            <a href={data.cta.url}>{data.cta.title}</a>
          </div>
          <p dangerouslySetInnerHTML={{ __html: data.body_copy }}></p>
        </div>

      </div>

      <div className="marquee-container bottom">
        {/* <div className="marquee">
            <span className="partner">Become a partner</span>
            <span className="support">Support Arts Help</span>
        </div> */}
      </div>
    </section>
  )
}
