import { useEffect, useState, useContext } from 'react'
import './About.scss'

export default function About({ data }) {

  return (
    <section className="about" style={{ backgroundImage: `url(${data.background_image.url})` }}>
      <div className="container">
        <div className="content">
          <p dangerouslySetInnerHTML={{ __html: data.body_copy }}></p>
          <img src={data.featured_image.url} />
        </div>
      </div>
    </section>
  )
}
