import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from 'assets';
import _ from 'lodash';
import './Hero.scss';

export default function Hero({ post }) {
  const [coAuthors, setCoAuthors] = useState([]);
  const primaryTag = post && post._embedded['wp:term'] ? _.flatten(post._embedded['wp:term']).filter(term => term._links.up)[0] : null;
  const formattedDate = (str) => {
    const date = new Date(str);
    const month = date.toLocaleString('default', { month: 'long' });
    return `${month} ${date.getDate()}, ${date.getFullYear()}`;
  }
  const series = _.flatten(post._embedded['wp:term']).find(term => term.taxonomy === 'series');
  const seriesNameRepeated = series ? Array(10).fill(series.name) : null;

  useEffect(() => {
    const getAuthors = async () => {
      const response = await fetch(`${API_URL}/users?include=${_.uniq(post.acf.co_authors).join(',')}`);
      const data = await response.json();

      setCoAuthors(data);
    }

    if (post.acf.co_authors) getAuthors();
  }, [post.acf.co_authors]);

  useEffect(() => {
    console.log(primaryTag);
  }, [primaryTag])

  return (
    <section className={`post-hero images-${post.acf.featured_images ? post.acf.featured_images.length : 0}`}>
      {series && <div className="series-banner">
        {seriesNameRepeated.map((name, index) => <span key={index}>{name}</span>)}
      </div>}


      {post.acf.featured_images &&
        <div className="post-image">
          <img src={!post.acf.featured_images[0].image ? post.acf.featured_images[0].url : post.acf.featured_images[0].image} alt={post.acf.featured_images[0].image.title} />
        </div>
      }

      <div className="post-content">
        {primaryTag && <Link className="post-tag" to={`/editorial/tag/${primaryTag.name.toLowerCase().replace(/[^\w\s]/g, '').replace(/\s+/g, '-')}`} style={{ '--colorScheme' : primaryTag.acf.color}}><span dangerouslySetInnerHTML={{ __html: primaryTag.name }}></span></Link> }
        <h1 className="post-title" dangerouslySetInnerHTML={{__html: post.title.rendered}}></h1>

        <div className="post-meta">
          <p className="post-author">
            <span>By </span>
            <span>{post._embedded.author[0].acf.display_name}</span>
            {coAuthors.map(author => (
              <span key={author.id}>{author.acf.display_name}</span>
            ))}
          </p>

          <p className="post-date">{formattedDate(post.date)}</p>
        </div>
      </div>

      {post.acf.featured_images && post.acf.featured_images.length > 1 &&
        <div className="post-image">
          <img src={!post.acf.featured_images[1].image ? post.acf.featured_images[1].url : post.acf.featured_images[1].image} alt={post.acf.featured_images[1].image.title} />
        </div>
      }
    </section>
  )
}
