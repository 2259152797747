import Hero from './Hero/Hero'
import FeaturedArticles from './FeaturedArticles/FeaturedArticles'
import EditorsPicks from './EditorsPicks/EditorsPicks'
import Collection from './Collection/Collection'
import FeaturedSeries from './FeaturedSeries/FeaturedSeries'
import TagsBrowser from './TagsBrowser/TagsBrowser'
import ArticleRow from './ArticleRow/ArticleRow'
import Callout from './Callout/Callout'
import TagsList from './TagsList/TagsList'

export default function Component({ component, pageData }) {
  const components = {
    hero: Hero,
    featured_articles: FeaturedArticles,
    editors_picks: EditorsPicks,
    articles_collection: Collection,
    featured_series: FeaturedSeries,
    tags_browser: TagsBrowser,
    article_row: ArticleRow,
    callout: Callout,
    tags_list: TagsList,
  }
  const Component = components[component.acf_fc_layout];

  if (Component) {
    return (
      <Component data={component} pageData={pageData} />
    )
  }

  return null;
}
