import { useEffect, useState, useRef } from 'react'
import './BitcoinConsumes.scss';

import Tvs from './../../assets/ccc-energy-tvs.jpg';
import Kettle from './../../assets/ccc-energy-kettle.jpg';
import Uae from './../../assets/ccc-energy-uae.jpg';

export default function BitcoinConsumes() {
  const windowHeight = window.innerHeight;
  const ref = useRef(null);
  const scrollRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollMeter, setScrollMeter] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const sectionTop = ref.current.getBoundingClientRect().top;
      const scrollSectionTop = scrollRef.current.getBoundingClientRect().top;
      setScrollPosition(scrollSectionTop);
      setIsFixed(sectionTop <= 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  useEffect(() => {
    const meter = (Math.abs(scrollPosition) / (windowHeight * 3) * 100);
    if (isFixed) {
      setScrollMeter(meter)
    }
  }, [scrollPosition])


  return (
    <>
      <div className="parallax-section-anchor bitcoin-consumes desktop" ref={ref}></div>
      <section className={`bitcoin-consumes desktop parallax-section-scroll ${isFixed ? 'active' : ''}`} ref={scrollRef}>
        <div className={`ccc-energy-parallax ${isFixed && scrollMeter < 100 ? 'fixed' : ''}`}>
          <div className="ccc-energy-parallax-content">
            <div className="container">
              <ul className="ccc-energy-stats">
                <li className="ccc-energy-stat ccc-cols">
                  <div className="ccc-energy-stat-info ccc-col">
                    <h2>Did you know?<br /> Bitcoin consumes as much energy as: </h2>
                    <div className="ccc-slides">
                      <p className="ccc-ghost"><strong>All the teakettles in the UK, boiling continuously for 18 years</strong></p>
                      <p className={`ccc-slide ccc-slide-1 ${ scrollPosition > -(windowHeight) ? 'active' : ''}`}><strong>All the TVs in the US</strong></p>
                      <p className={`ccc-slide ccc-slide-2 ${scrollPosition <= -(windowHeight) && scrollPosition > -(windowHeight*2) ? 'active' : ''}`}><strong>All the teakettles in the UK, boiling continuously for 18 years</strong></p>
                      <p className={`ccc-slide ccc-slide-3 ${scrollPosition <= -(windowHeight*2) ? 'active' : ''}`}><strong>The United Arab Emirates</strong></p>
                    </div>
                    <div className="ccc-scroll-indicator">
                      <div className="ccc-scroll-indicator-meter">
                        <div className="ccc-scroll-indicator-meter-fill" style={{width: scrollMeter + "%"}}></div>
                      </div>
                      <span></span>
                    </div>
                  </div>
                  <div className="ccc-col image-container">
                    <div className="ccc-slides">
                      <img className="ccc-ghost" src={Tvs} alt="Stack of old CRT televisions" />
                      <img className={`ccc-slide ccc-slide-1 ${scrollPosition > -(windowHeight) ? 'active' : ''}`} src={Tvs} alt="Stack of old CRT televisions" />
                      <img className={`ccc-slide ccc-slide-2 ${scrollPosition <= -(windowHeight) && scrollPosition > -(windowHeight*2) ? 'active' : ''}`} src={Kettle} alt="Tea kettle on stove boiling" />
                      <img className={`ccc-slide ccc-slide-3 ${scrollPosition <= -(windowHeight*2) ? 'active' : ''}`} src={Uae} alt="Skyline of Dubai" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
        </div>
      </div>
    </section>
    </>
  )
}
