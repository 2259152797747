export const SITE_URL = 'https://artshelp.thirdrailcreative.com/';
export const API_URL = `${SITE_URL}wp-json/wp/v2`;
export const PUBLIC_URL = window.location.host.indexOf('localhost') > -1 ? '' : '/wp-content/themes/3rc';

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyA2roO32-1pqGlaHM9zChDPvZJWkcUiEZA",
  authDomain: "arts-help.firebaseapp.com",
  projectId: "arts-help",
  storageBucket: "arts-help.appspot.com",
  messagingSenderId: "246117492624",
  appId: "1:246117492624:web:47015d1d1ffa7bd014b515"
};

export const FIREBASE_FUNCTIONS_URL = window.location.host.indexOf('localhost') > -1 ? 'http://localhost:5001/arts-help/us-central1' : '';
