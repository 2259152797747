import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from 'assets';
import './TopStories.scss';
import Post from 'components/Post/Post';

import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';

export default function TopStories({ data }) {
  const [categories, setCatogories] = useState([]);
  const [posts, setPosts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [listVisible, setListVisible] = useState(false);


  const carouselBreakpoints = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      partialVisibilityGutter: 30
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 1,
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
      partialVisibilityGutter: 30
    }
  }

  const toggleList = () => {
    setListVisible(prevListVisible => !prevListVisible);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    toggleList();
  };

  useEffect(() => {
    const getCategories = async () => {
      const response = await fetch(`${API_URL}/categories?_fields=id,name,slug,acf,parent&per_page=100&exclude=1,50`);
      const data = await response.json();
      const filteredCategories = data.filter(item => item.parent === 0);

      console.log(filteredCategories);

      if (filteredCategories.length) {
        setCatogories(filteredCategories);
      }
    }

    getCategories();
  }, [data])

  useEffect(() => {
    if (!selectedCategory) {
      setSelectedCategory(categories[0]);
    }
  }, [categories, selectedCategory])

  useEffect(() => {
    if (selectedCategory) {
      const getPosts = async () => {
        const response = await fetch(`${API_URL}/posts?categories=${selectedCategory.id}&per_page=4&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);

        const responseData = await response.json();

        if (responseData.length) setPosts(responseData);
      }

      getPosts();
    }
  }, [selectedCategory])




  if (!categories.length || !selectedCategory) return null;

  return (
    <section className="top-stories collection">
      <div className='category-list'>
        <div className="container">
          <div className="header">
            <h2>
              Top Stories on
              <div className={`select-category ${listVisible ? 'active' : 'inactive'}`} onClick={toggleList}>{selectedCategory.name} + Art
              </div>
              <ul className={`${listVisible ? 'active' : 'inactive'}`}>
                {categories.map(category => (
                  <li key={category.name} onClick={() => handleCategoryClick(category)}>{category.name}</li>
                ))}
              </ul>
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="space-container">
          <div className={`collection-content layout-grid desktop`}>
            {posts.map(post => (
              <Post post={post} key={post.id} />
            ))}
          </div>
          <Carousel responsive={carouselBreakpoints} className="collection-content mobile">
            {posts.map(post => (
              <Post post={post} key={post.id} />
            ))}
          </Carousel>
            <Link to={`/editorial/${selectedCategory.parent ? 'tag' : 'category'}/${selectedCategory.slug}`} aria-label={`View more articles about ${selectedCategory.name}`} className="view-more mobile">View More</Link>
        </div>
      </div>
    </section>
  )
}
