import { useState } from 'react'
import { Helmet } from 'react-helmet';
import { FIREBASE_CONFIG } from 'assets';
import { useLocation, useNavigate } from 'react-router-dom';
import './Signin.scss'

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';

initializeApp(FIREBASE_CONFIG);

export default function Signin() {
  const navigate = useNavigate();
  const location = useLocation();
  const mode = location.pathname.slice(1);
  const modeString = `${mode.slice(0, 1).toUpperCase()}${mode.slice(1)}`;

  const auth = getAuth();
  const [formEmail, setFormEmail] = useState('holden@thirdrailcreative.com');
  const [formPassword, formSetPassword] = useState('password');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mode === 'signup') {
      signUp();
    } else {
      signIn();
    }
  }

  const signIn = () => {
    signInWithEmailAndPassword(auth, formEmail, formPassword)
      .then(userCredential => {
        authSuccess(userCredential);
      })
      .catch(error => {
        authError(error);
      });
  }

  const signUp = () => {
    createUserWithEmailAndPassword(auth, formEmail, formPassword)
      .then(userCredential => {
        authSuccess(userCredential);
      })
      .catch(error => {
        authError(error);
      });
  }

  const authSuccess = (userCredential) => {
    const user = userCredential.user;
    console.log('user');
    console.log(user);
    navigate('/');
  }
  const authError = () => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
    setError(errorMessage);
  }

  return (
    <div className="page signin">
      <Helmet>
        <title>ArtsHelp | {modeString}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <div className="container">
        <h1>{modeString}</h1>
        <form onSubmit={handleSubmit}>
          <input type="email" value={formEmail} onChange={e => setFormEmail(e.target.value)} />
          <input type="password" value={formPassword} onChange={e => formSetPassword(e.target.value)} />
          <input type="submit" value={modeString} onClick={handleSubmit} />
        </form>

        {error &&
          <p>{error}</p>
        }
      </div>
    </div>
  )
}
