import { useEffect, useState } from 'react'
import './Quote.scss';
import Rellax from 'rellax';

import Gov from './../../assets/ccc-gov.png';

export default function Quote() {
  const [quoteVisible, setQuoteVisible] = useState()

  const Graphic = () => {
    const rellaxInit = () => {
      new Rellax('.ccc-quote-graphic', {
        vertical: true,
        center: false,
      });
    }

    useEffect(() => {
      rellaxInit();
    }, [quoteVisible]);

    return (
      <img
        src={Gov}
        alt="Headshot of António Guterres, UN Secretary-General"
        className="parallax-layer rellax ccc-quote-graphic"
        data-rellax-vertical-scroll-axis="xy"
        data-rellax-vertical-speed="2.1"
        data-rellax-percentage="0.5"
      />
    )
  }


  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setQuoteVisible(true);
      } else {
        setQuoteVisible(false);
      }
    })
    observer.observe(document.querySelector('.ccc-quote'));
  }, [])


  return (
    <section className="ccc-quote">
      <div className="container">
        <div className="ccc-cols">
          <div className="ccc-col">
            <blockquote>“We don't have a minute to lose. The well-being of people and our planet demands urgent Climate Action now.”<cite><strong>— António Guterres, </strong>UN Secretary-General</cite></blockquote>
          </div>
          <div className="ccc-col">
            <Graphic />
            {/* <img src={Gov}  className="ccc-quote-graphic"/> */}
          </div>
        </div>
      </div>
    </section>
  )
}
