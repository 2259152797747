import * as React from 'react';
import { useState } from 'react';
import { Router, Routes, Route } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { OptionsContextProvider } from './OptionsContext';
import { UserContextProvider } from './UserContext';

// Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

// Pages
import Home from './pages/Home/Home';
import Signin from './pages/Signin/Signin';
import Editorial from './pages/Editorial/Editorial';
import Post from './pages/Editorial/pages/Post/Post';
import Category from './pages/Editorial/pages/Category/Category';
import Series from './pages/Editorial/pages/Series/Series';
import NotFound from './pages/404/404';
import Tag from './pages/Editorial/pages/Tag/Tag';
import CCC from './pages/CCC/CCC';
import GetInvolved from './pages/GetInvolved/GetInvolved';
import Team from './pages/Team/Team';
import NewsPress from 'pages/NewsPress/NewsPress';
import SDGs from 'pages/SDGs/Sdgs';

function App() {

  return (
    <UserContextProvider>
      <OptionsContextProvider>
        <Header />
        <div className="content-container">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="signin" element={<Signin />} />
              <Route path="signup" element={<Signin />} />
              <Route path="editorial" element={<Editorial />} />
              <Route path="editorial/:slug" element={<Post />} />
              <Route path="editorial/category/:slug" element={<Category />} />
              <Route path="editorial/series/:slug" element={<Series />} />
              <Route path="editorial/tag/:slug" element={<Tag />} />
              <Route path="ccc/" element={<CCC />} />
              <Route path="getinvolved/" element={<GetInvolved />} />
              <Route path="getinvolved/:slug" element={<GetInvolved />} />
              <Route path="team" element={<Team />} />
              <Route path="news" element={<NewsPress />} />
              <Route path="sdgs" element={<SDGs />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        <Footer />
      </OptionsContextProvider>
    </UserContextProvider>
  );
}

export default App;
