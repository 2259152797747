import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { API_URL } from 'assets';
import './Home.scss'

import Lottie from 'lottie-react';
import LoadingAnimation from 'assets/loading-loop.json'

import Component from './components/Component'

import Hero from './components/Hero/Hero';
import Slider from './components/Slider/Slider';

export default function Home() {
  const [page, setPage] = useState(null);

  useEffect(() => {
    const getPage = async () => {
      const pageResponse = await fetch(`${API_URL}/pages/63?v=98`);
      const pageData = await pageResponse.json();
      setPage(pageData);
    }

    getPage();
  }, []);

  if (!page) return (
    <div className="container">
      <Lottie animationData={LoadingAnimation} loop={true} className="loading-animation"/>
    </div>
);

  return (
    <div className="page home">
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      {/* <Hero />
      <Slider /> */}

      {page.acf.components.map((component, index) => <Component component={component} key={index}/>)}
    </div>
  )
}
