import './Hero.scss';

import HeroGif from './../../assets/ccc-hero-banner-2.gif';

export default function Hero() {

  return (
    <section className="ccc-hero">
      <div className="container">
        <div className="ccc-cols">
          <div className="ccc-col">
            <h1>Calling all artists fighting climate change</h1>
            <h2>Learn how to earn your piece of our $3.5M Artist Grant.</h2>
            <p>Start by taking our Mini Master classes to become a Verified Conscious Crypto Creator.</p>
            <div className="ccc-hero-btns">
                <a href="/signup/?referrer=ccc" className="trc-button black">Sign up</a>
                <p className="signin">Already have an account? <a href="/signin/?referrer=ccc">Sign in</a></p>
            </div>
          </div>
          <div className="ccc-col">
            <img src={HeroGif} alt="Conscious Crypto Creators hero animation" />
          </div>
        </div>

        <div className="ccc-scroll-indicator">
          <span className="ccc-scroll-label">SCROLL TO LEARN MORE</span>
          <span className="ccc-scroll-icon"></span>
        </div>
      </div>
    </section>
  )
}
