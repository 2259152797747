import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from 'assets';
import './LatestEditorial.scss';
import Post from 'components/Post/Post';

export default function LatestEditorial({ data }) {
  const [posts, setPosts] = useState([]);
  const [bucketOne, setBucketOne] = useState([]);
  const [bucketTwo, setBucketTwo] = useState([]);
  const [bucketThree, setBucketThree] = useState([]);
  const [bucketFour, setBucketFour] = useState([]);

  const splitToBuckets = () => {
    const bucket1 = [posts[0]];
    const bucket2 = [posts[1]];
    const bucket3 = posts.slice(2, 5);
    const bucket4 = [posts[5]];

    setBucketOne(bucket1);
    setBucketTwo(bucket2);
    setBucketThree(bucket3);
    setBucketFour(bucket4);
  }

  useEffect(() => {
    const getPosts = async () => {
      const response = await fetch(`${API_URL}/posts?per_page=6&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const responseData = await response.json();

      if (responseData.length) setPosts(responseData);
    }

    getPosts();
  }, [])

  useEffect(() => {
    if (posts.length) {
      splitToBuckets();
    }
  }, [posts])

  if (!posts.length) return null;

  return (
    <section className="latest-editorial">
      <div className="container">
          {/* {posts.map(post => (
            <Post post={post} key={post.id} />
          ))} */}

        <div className="sidebar">
          <h2>Latest in <span>EDITORIAL</span></h2>
          <a href="/editorial" className="view-more">View more articles</a>
        </div>
        <div className="article-display">
          <div className="bucket one">
            {bucketOne.map(post => (
              <Post post={post} key={post.id} />
            ))}
          </div>
          <div className="bucket two">
            {bucketTwo.map(post => (
              <Post post={post} key={post.id} />
            ))}
          </div>
          <div className="bucket three ">
            {bucketThree.map(post => (
              <Post post={post} key={post.id} />
            ))}
          </div>
          <div className="bucket four">
            {bucketFour.map(post => (
              <Post post={post} key={post.id} />
            ))}
          </div>
        </div>

      </div>
    </section>
  )
}
