import { useEffect, useState, useContext } from 'react'
import { Link, NavLink } from 'react-router-dom';
import './Slider.scss'
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';

import RightArrow from 'img/arrow-right.svg';

export default function Slider({data}) {
  const carouselBreakpoints = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  }

  return (
    <section className="slider">
      <Carousel responsive={carouselBreakpoints} showDots={true} infinite={true} renderArrowsWhenDisabled	>
        {data.slides.map((slide) => (
          <div className="slide" key={slide.headline}>
            <div className="slide-bg">
              <img src={slide.background_image.url} />
            </div>
            <div className="container">
              <div className="slide-content">
                <h3 dangerouslySetInnerHTML={{__html: slide.headline}}></h3>
                <p dangerouslySetInnerHTML={{__html: slide.subheadline}}></p>
                <a className="btn" href={slide.cta.url}>{slide.cta.title}</a>
              </div>
              {slide.slide_image.url ?
                <div className="slide-image">
                  <img src={slide.slide_image.url} alt={slide.headline}/>
                </div>
                : null
              }
            </div>
          </div>
        ))}
    </Carousel>
    </section>
  )
}
