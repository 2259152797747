import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { API_URL } from 'assets';
import _ from 'lodash';
import './Post.scss';

import Lottie from 'lottie-react';
import LoadingAnimation from 'assets/loading-loop.json'

import CategoriesNav from 'pages/Editorial/components/CategoriesNav/CategoriesNav'

import Hero from './components/Hero/Hero';
import Share from './components/Share/Share';
import Author from './components/Author/Author';
import AuthorArticles from './components/AuthorArticles/AuthorArticles';
import RelatedArticles from './components/RelatedArticles/RelatedArticles';
import PostComponent from './PostComponents/PostComponent';

export default function Post() {
  const params = useParams();
  const [post, setPost] = useState(null);
  const [user, setUser] = useState([]);
  const postTags = post && post._embedded['wp:term'] ? _.flatten(post._embedded['wp:term']).filter(term => term._links.up).slice(1) : [];

  useEffect(() => {
    const getPost = async () => {
      const postResponse = await fetch(`${API_URL}/posts?slug=${params.slug}&_embed=author,wp:term&_fields=id,categories,slug,title,author,acf,excerpt,date,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const post = await postResponse.json();
      setPost(post[0]);
    }

    getPost();
  }, [params.slug]);

  useEffect(() => {
    const getUser = async () => {
      const postAuthor = post.acf.co_authors ? [post.author, ...post.acf.co_authors] : [post.author];
      const userResponse = await fetch(`${API_URL}/users?include=${_.uniq(postAuthor).join(',')}`);
      const user = await userResponse.json();
      setUser(user);
    }

    if (post) getUser();
  }, [post]);

  if (!post) return (
    <div className="container">
      <Lottie animationData={LoadingAnimation} loop={true} className="loading-animation"/>
    </div>
  );

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page-post' }}>
        <title>Arts Help | {post.title.rendered}</title>
        <meta name="description" content={post.excerpt.rendered ? post.excerpt.rendered.replace(/(<([^>]+)>)/ig, '') : ''} />
      </Helmet>

      <CategoriesNav />

      <div className="container">
        <article className="post">
          <Hero post={post}/>

          <div className="post-body">
            <aside className="post-sidebar">
              {postTags.length ?
                <div className="post-tags">
                  {postTags.map(tag => (
                    <a className="tag" href={`/editorial/tag/${tag.slug}`} key={tag.slug} style={{ '--colorScheme' : tag.acf.color}}>{tag.name}</a>
                  ))}
                </div>
              : null}

              <Share stack="true" />
            </aside>

            <div className="post-content">
              {post.excerpt.rendered && <div className="post-excerpt" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />}

              {post.acf.post_components ? post.acf.post_components.map((component, index) => (
                <PostComponent component={component} key={index} />
              )) : null}

              <div className="post-share">
                <Share />
              </div>
            </div>

          </div>
        </article>

        <section className="post-footer">
          <div className="post-author">
            <h2>More posts from this author</h2>
            <Author user={user} />
            {/* <AuthorArticles post={post} user={user}/> */}
          </div>

          <div className="related-articles">
            <h2>Related Articles</h2>
            {/* <RelatedArticles post={post}/> */}
          </div>
        </section>
      </div>
    </>
  )
}
