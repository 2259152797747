import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { API_URL } from 'assets';
import './Team.scss';

import Component from './components/Component'

import Lottie from 'lottie-react';
import LoadingAnimation from 'assets/loading-loop.json'

export default function Team() {
  const [page, setPage] = useState(null);

  useEffect(() => {
    const getPage = async () => {
      const pageResponse = await fetch(`${API_URL}/pages/803?x=4`);
      const pageData = await pageResponse.json();
      setPage(pageData);
    }

    getPage();
  }, []);

  if (!page) return (
      <div className="container">
        <Lottie animationData={LoadingAnimation} loop={true} className="loading-animation"/>
      </div>
  );

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page team' }}>
        <title>Arts Help | Team</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      {page.acf.team_components.map((component, index) => <Component component={component} key={index}/>)}
    </>
  )
}
