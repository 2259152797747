import { useEffect, useState } from 'react';
import './Toggle.scss';


export default function Toggle({toggleStatus}) {
  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    toggleStatus(isChecked);
  }, [isChecked])

  return (
    <section className={`news-press-toggle ${!isChecked ? "news-active" : "press-active"}`}>
      <div className="container">
        <div className="toggle-container">
          <h2 className={!isChecked ? "active" : "inactive"}>News</h2>
          <div className="toggle-switch">
            <label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={toggleSwitch}
              />
              <span className="slider"></span>
            </label>
          </div>
          <h2 className={isChecked ? "active" : "inactive"}>Press</h2>
        </div>
      </div>
    </section>
  )
}
