import { useEffect, useState, useContext } from 'react'
import './Hero.scss'

export default function Hero({ data }) {

  return (
    <section className="hero" style={{ backgroundImage: `url(${data.background_image.url})` }}>
      <div className="image image-1">
        <img src={data.image_1.url} alt=""/>
      </div>
      <div className="container">
        <div className="page-title">
          <h1 dangerouslySetInnerHTML={{__html: data.title}}></h1>
        </div>
        <div className="body-copy">
          <p dangerouslySetInnerHTML={{__html: data.body_copy}}></p>
        </div>
      </div>
      <div className="image image-2">
        <img src={data.image_2.url} alt=""/>
      </div>
    </section>
  )
}
