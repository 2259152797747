import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API_URL } from 'assets';
import './Collection.scss';
import Post from 'components/Post/Post';

import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';

export default function Collection({ data }) {
  const [posts, setPosts] = useState([]);

  const carouselBreakpoints = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      partialVisibilityGutter: 30
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 1,
      partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
      partialVisibilityGutter: 30
    }
  }

  useEffect(() => {
    const getPosts = async () => {
      const response = await fetch(`${API_URL}/posts?categories=${data.category.term_id}&per_page=${data.layout === 'grid' ? 4 : 3}&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const responseData = await response.json();

      if (responseData.length) setPosts(responseData);
    }

    getPosts();
  }, [data.category.term_id, data.layout])

  if (!posts.length) return null;

  return (
    <section className="collection homepage">
      <div className="container">
        <div className="space-container">
          <div className="collection-header">
            <h2><span>Articles on</span> {data.category.name}</h2>
            <Link to={`/editorial/${data.category.parent ? 'tag' : 'category'}/${data.category.slug}`} aria-label={`View more articles about ${data.category.name}`} className="view-more desktop">View More</Link>
          </div>

          <div className={`collection-content layout-${data.layout} desktop`}>
            {posts.map(post => (
              <Post post={post} key={post.id} />
            ))}
          </div>
          <Carousel responsive={carouselBreakpoints} className="collection-content mobile">
            {posts.map(post => (
              <Post post={post} key={post.id} />
            ))}
          </Carousel>
            <Link to={`/editorial/${data.category.parent ? 'tag' : 'category'}/${data.category.slug}`} aria-label={`View more articles about ${data.category.name}`} className="view-more mobile">View More</Link>
        </div>
      </div>
    </section>
  )
}
