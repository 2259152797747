import { useState, useEffect } from 'react';
import './Share.scss';
import FacebookIcon from './assets/facebook.svg';
import TwitterIcon from './assets/twitter.svg';
import LinkIcon from './assets/link.svg';

export default function Share({ stack }) {
  const [showLinkCopied, setShowLinkCopied] = useState(false);
  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setShowLinkCopied(true);
    });
  };

  useEffect(() => {
    setTimeout(() => setShowLinkCopied(false), 2000);
  }, [showLinkCopied])

  return (
    <div className={`share${stack ? ' stack' : ''}`}>
      <span>Share:</span>

      <div className="share-methods">
        <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Share this article on Facebook">
          <img src={FacebookIcon} alt="" />
        </a>

        <a href={`https://twitter.com/intent/tweet?url=${window.location.href}`} target="_blank" rel="noopener noreferrer" aria-label="Share this article on Twitter">
          <img src={TwitterIcon} alt="" />
        </a>

        {showLinkCopied ? <span>Link copied</span> : <button className="share-button" onClick={() => copyLink()} aria-label="Copy link to this article on your clipboard">
          <img src={LinkIcon} alt="" />
        </button>}
      </div>
    </div>
  );
};
