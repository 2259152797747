import Hero from './Hero/Hero'
import Slider from './Slider/Slider'
import LatestEditorial from './LatestEditorial/LatestEditorial'
import Collection from './Collection/Collection'
import GetInvolved from './GetInvolved/GetInvolved'
import LatestNews from './LatestNews/LatestNews'
import OngoingProgramming from './OngoingProgramming/OngoingProgramming'
import TopStories from './TopStories/TopStories'
import ArticleRow from './ArticleRow/ArticleRow'
import Subscribe from './Subscribe/Subscribe'


export default function Component({ component, pageData }) {
  const components = {
    hero: Hero,
    slider: Slider,
    latest_editorial: LatestEditorial,
    collection: Collection,
    get_involved: GetInvolved,
    latest_news: LatestNews,
    ongoing_programming: OngoingProgramming,
    subscribe: Subscribe,
    top_stories: TopStories,
    article_row: ArticleRow,
  }
  const Component = components[component.acf_fc_layout];

  if (Component) {
    return (
      <Component data={component} pageData={pageData} />
    )
  }

  return null;
}
