import { useState, useEffect } from 'react';
import { API_URL } from 'assets';
import './EditorsPicks.scss'
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import Post from 'components/Post/Post';

export default function EditorsPicks() {
  const [posts, setPosts] = useState([]);
  const carouselBreakpoints = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  }

  useEffect(() => {
    const getPosts = async () => {
      const response = await fetch(`${API_URL}/posts?acf_editors_pick=1&per_page=10&_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_links.author,_embedded.wp:term,_embedded.author`);
      const data = await response.json();

      setPosts(data);
    }

    getPosts();
  }, [])

  if (!posts.length) return null;

  return (
    <section className="editors-picks">
      <div className="editors-picks-title">
        <h2>Editor&rsquo;s Picks</h2>
      </div>

      <div className="article-carousel">
        <Carousel responsive={carouselBreakpoints}>
          {posts.map(post => (
            <Post post={post} key={post.id} />
          ))}
        </Carousel>
      </div>
    </section>
  )
}
