import { useEffect, useState, useContext } from 'react'
import './OngoingProgramming.scss'


export default function OngoingProgramming({ data }) {

  if (!data) return null;

  return (
    <section className="ongoing-programming">
      <div className="container">
        <div className="header">
          <h2>Ongoing Programming</h2>
        </div>
        <div className="content" style={{backgroundImage: `url(${data.background_image.url})`}}>
          <div className="image">
            <img src={data.section_image.url} alt={data.header}/>
          </div>
          <div className="copy">
            <h3 dangerouslySetInnerHTML={{ __html: data.header }}></h3>
            <p dangerouslySetInnerHTML={{ __html: data.body_copy }}></p>
            <a href={data.cta.url} className="btn">{data.cta.title}</a>
          </div>
        </div>
      </div>
    </section>
  )
}
