import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { OptionsContext } from 'OptionsContext';
import './Footer.scss';

export default function Footer() {
  const options = useContext(OptionsContext);

  return (
    <footer className="main-footer">
      <div className="container">
        <div className="footer-cols">
          <div className="footer-logo">
            <img className="logo" src={options.logo_secondary.url} alt={options.logo_secondary.alt} />
          </div>

          <nav className="footer-nav">
            {options.secondary_nav.map(group => (
              <div className="nav-group" key={group.name}>
                <strong>{group.name}</strong>
                <ul>
                  {group.links.map(link => (
                    <li key={link.link.title}>
                      <NavLink to={link.link.url}>{link.link.title}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </nav>

          <div className="footer-ctas">
            {/* <div className="footer-newsletter">
              <strong>Subscribe to our newsletter</strong>
            </div> */}

            <strong>Stay connected</strong>
            <div className="footer-social-links">
              {options.social_links.map(link => (
                <a className="footer-social-link" href={link.link.url} key={link.link.title} target={link.link.target}><img src={link.icon} alt={link.link.title} /></a>
              ))}
            </div>
          </div>
        </div>

        <p className="copyright">{options.copyright}</p>
      </div>
    </footer>
  )
}
