import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import './Stats.scss';


export default function Stats(component) {

  const { data } = component;

  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });

  const [animatedStats, setAnimatedStats] = useState([]);

  useEffect(() => {
    if (inView) {
      setAnimatedStats(data.stat_repeater.map((stat) => ({ ...stat, animatedValue: 0 })));
    }
  }, [inView, data.stat_repeater]);

  useEffect(() => {
    if (inView && animatedStats.length > 0) {
      const animationDuration = 300; // Adjust animation duration as needed
      const interval = animationDuration / 60; // 60 frames per second
      const stepValue = animatedStats[0].stat / (animationDuration / interval);

      const animationInterval = setInterval(() => {
        setAnimatedStats((prevStats) => {
          const updatedStats = prevStats.map((stat) => ({
            ...stat,
            animatedValue: Math.min(stat.stat, stat.animatedValue + stepValue),
          }));
          return updatedStats;
        });
      }, interval);

      return () => {
        clearInterval(animationInterval);
      };
    }
  }, [inView, animatedStats]);

  if (!component) return null;

  return (
    <section ref={inViewRef} className="team-stats">
      <div className="container">
        <div className="stat-container">
          {animatedStats.map((stat, index) => (
            <div className="stat" key={stat.label}>
              <p>
                <span>
                  {stat.stat_type === "dollar" ?
                    <>$</>
                    : null
                  }
                  {stat.animatedValue.toFixed(0)}
                  {stat.stat_type === "percentage" ?
                    <>%</>
                    : null
                  }
                </span>
                {stat.label}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
