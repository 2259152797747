import Hero from './Hero/Hero'
import Stats from './Stats/Stats'
import TeamMembers from './TeamMembers/TeamMembers'
import ContributingWriterCallout from './ContributingWriterCallout/ContributingWriterCallout'
import Advisory from './Advisory/Advisory'
import GetInvolvedCallout from './GetInvolvedCallout/GetInvolvedCallout'

export default function Component({ component, pageData }) {
  const components = {
    hero: Hero,
    statistics: Stats,
    team_members: TeamMembers,
    contributing_writer_callout: ContributingWriterCallout,
    advisory: Advisory,
    get_involved_callout: GetInvolvedCallout,
  }
  const Component = components[component.acf_fc_layout];

  if (Component) {
    return (
      <Component data={component} pageData={pageData} />
    )
  }

  return null;
}
