import { useEffect, useState, createContext } from 'react';

import { FIREBASE_CONFIG } from 'assets';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [uid, setUid] = useState(null);
  const [userData, setUserData] = useState(null);

  const app = initializeApp(FIREBASE_CONFIG);
  const auth = getAuth();
  const db = getFirestore(app);

  const signOut = async () => {
    await auth.signOut();
    setUserData(null);
  }
  const updateUserData = data => {
    updateDoc(doc(db, 'users', uid), data);
  }

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);

        setUid(user.uid);

        if (docSnap.exists()) {
          setUserData(docSnap.data());
        } else {
          console.log('User does not exist, creating...');
          const initialUserData = {
            name: '',
          }
          await setDoc(doc(db, 'users', user.uid), initialUserData);
          setUserData(initialUserData);
        }
      }
    });
  }, [auth, db]);


  return (
    <UserContext.Provider value={{userData, signOut, updateUserData}}>
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserContextProvider };
