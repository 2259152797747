import { useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { UserContext } from 'UserContext';
import { OptionsContext } from 'OptionsContext';
import './Header.scss';

export default function Header() {
  const user = useContext(UserContext);
  const options = useContext(OptionsContext);
  const location = useLocation();
  const isNotRootPath = location.pathname !== '/';

  const [searchValue, setSearchValue] = useState('');
  const [menuActive, setMenuActive] = useState(false);
  const toggleMenu = () => setMenuActive(menuActive => !menuActive);

  const [collapsedSubnavItems, setCollapsedSubnavItems] = useState([]);
  const toggleSubnavCollapse = name => {
    if (collapsedSubnavItems.includes(name)) {
      setCollapsedSubnavItems(collapsedSubnavItems.filter(itemName => itemName !== name));
    } else {
      setCollapsedSubnavItems([...collapsedSubnavItems, name]);
    }
  };

  const submitSearch = () => {
  }

  return (
    <header className="header" id="header">
      <div className="header-main container">
        <button className={`btn-nav${menuActive ? ' active' : ''}`} onClick={toggleMenu}>Open navigation</button>

        {isNotRootPath && (
          <NavLink to="/" className="header-logo">
            <img src={options.logo_primary.url} alt={options.logo_primary.alt} />
          </NavLink>
        )}

        <div className="user-tools">
          {user.userData ?
            <button className="btn" onClick={() => user.signOut()}>Log Out</button> :
            <a href="/signin" className="btn">Log In</a>
          }
          <form onSubmit={submitSearch} className="search">
            <button type="submit" value="submit"></button>
            <label htmlFor="search">Search</label>
            <input name="search" value={searchValue} onChange={e => setSearchValue(e.target.value)} placeholder="SEARCH" />
          </form>
        </div>
      </div>

      <nav className={`header-nav${menuActive ? ' active' : ''}`}>
        <div className="container">
          <ul className="header-nav-items">
            {options.primary_nav.map(item => (
              <li key={item.link.title} className={`${collapsedSubnavItems.includes(item.link.title) && item.children ? 'active' : ''}`}>
                {item.children ?
                  <>
                    <button onClick={() => toggleSubnavCollapse(item.link.title)} className="nav-item subnav-toggle">{item.link.title}</button>
                    <ul className={`header-nav-item-children ${collapsedSubnavItems.includes(item.link.title) ? 'active' : ''}`}>
                      {item.children.map(child => (
                        <li key={child.link.title}>
                          <NavLink to={child.link.url} onClick={toggleMenu}>{child.link.title}</NavLink>
                        </li>
                      ))}
                    </ul>
                  </>
                  :
                  <NavLink to={item.link.url} onClick={toggleMenu} className="nav-item">{item.link.title}</NavLink>
                }
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </header>
  )
}
