import { useEffect, useState } from 'react'
import { API_URL } from 'assets';
import './FeaturedSeries.scss'
import { template } from 'lodash';

import ExclusiveInterviewsTitle from './assets/exclusive-interviews-title.svg';
import BlackArtistryTitle from './assets/black-artistry-title.svg';

export default function FeaturedSeries(component) {
  const [featuredSeriesLeft, setFeaturedSeriesLeft] = useState(null);
  const [featuredSeriesRight, setFeaturedSeriesRight] = useState(null);

  const Template = ({ layout }) => {
    if (layout.acf.template === 'template-1') {
      return (
        <div className={`featured-series ${layout.acf.template}`}>
          <div className="container">
            <div className="featured-series-content">
              {layout.name === "Exclusive Interviews" ?
                <img src={ExclusiveInterviewsTitle} alt="Exclusive Interviews"/>
                :
                <h3>{layout.name}</h3>
              }
              <div className="description">
                <p>{layout.description}</p>
                <div className="cta">
                  <a href={layout.link} className="btn white">View more</a>
                </div>
              </div>
            </div>
            <div className="featured-image">
              <img src={layout.acf.featured_image} />
            </div>
          </div>
        </div>
      )
    } else if (layout.acf.template === 'template-2') {
      return (
        <div className={`featured-series ${layout.acf.template}`} style={{ backgroundImage: `url(${layout.acf.featured_image})`}}>
          <div className="container">
            <div className="featured-series-content">
              {layout.name === "Black Artistry Series 365" ?
                  <img src={BlackArtistryTitle} alt="Black Artistry Series 365"/>
                  :
                  <h3>{layout.name}</h3>
                }
              <p>{layout.description}</p>
              <div className="cta">
                <a href={layout.link} className="btn white">View more</a>
              </div>
            </div>
          </div>
        </div>
      )
    }
    console.log("layout: ", layout);
  }

  useEffect(() => {
    const getSeries = async () => {
      const seriesResponse1 = await fetch(`${API_URL}/series/${component.data.series_left}`);
      const seriesData1 = await seriesResponse1.json();
      const seriesResponse2 = await fetch(`${API_URL}/series/${component.data.series_right}`);
      const seriesData2 = await seriesResponse2.json();

      setFeaturedSeriesLeft(seriesData1);
      setFeaturedSeriesRight(seriesData2);
    }

    getSeries();
  }, []);

  useEffect(() => {
    console.log(featuredSeriesLeft);
    console.log(featuredSeriesRight);
  }, [featuredSeriesLeft, featuredSeriesRight])

  // if (!featuredSeriesLeft && !featuredSeriesRight) return null;
  if (!featuredSeriesRight) return null;

  return (
      <section className="featured-series-section">
        <Template layout={featuredSeriesLeft}/>
        <Template layout={featuredSeriesRight}/>
      </section>
  )
}
