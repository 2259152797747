import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { API_URL } from 'assets';
import './Tag.scss';

import Lottie from 'lottie-react';
import LoadingAnimation from 'assets/loading-loop.json'

import CategoriesNav from 'pages/Editorial/components/CategoriesNav/CategoriesNav'
import Posts from './Posts/Posts';
import TagsBrowser from 'pages/Editorial/components/TagsBrowser/TagsBrowser';

export default function Tag() {
  const params = useParams();
  const [tag, setTag] = useState(null);

  useEffect(() => {

    const getTag = async () => {
      const response = await fetch(`${API_URL}/categories?slug=${params.slug}`);
      const data = await response.json();
      setTag(data[0]);
    }

    getTag();
  }, [params.slug]);

  if (!tag) return (
    <div className="container">
      <Lottie animationData={LoadingAnimation} loop={true} className="loading-animation"/>
    </div>
  );

  return (
    <div className="page tag-index">
      <Helmet>
        <title>{tag.name} Articles</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <CategoriesNav />
      <Posts tag={tag} />
      <TagsBrowser />
    </div>
  )
}
