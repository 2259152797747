import React from 'react';
import { useEffect, useState, useContext, useRef } from 'react'
import { API_URL } from 'assets';
import './SdgList.scss'

import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';

import wcwdIcon from './assets/wcwd-icon.svg';
import wcwdIconMobile from './assets/wcwd-icon-mobile.svg';
import relatedStoriesIcon from './assets/related-stories.svg';
import relatedStoriesIconMobile from './assets/related-stories-mobile.svg';

export default function SdgList({ data, index }) {
  const [content, setContent] = useState(null);

  const carouselBreakpoints = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1
    }
  }

  useEffect(() => {
    if (data) {
      setContent(data.acf.sdg_list_display_content);
    }
  }, [data])

  if (!data || !content) return null;

  return (
      <>
      {/* <h3 dangerouslySetInnerHTML={{
        __html: index >= 9 ? `${index + 1} ${data.name}` : `0${index + 1} ${data.name}`
      }}>
      </h3> */}
      <div className={`sdg-title ${data.name.length > 20 ? "long" : ""}`}>
        <div className="sdg-number">
          {index >= 9 ?
            index + 1
            :
            "0" +  (index + 1)
          }
        </div>
        <h3 dangerouslySetInnerHTML={{__html: data.name }}></h3>
      </div>
          {content ?
            <div className="content">
              <div className="sdg-about">
                <h4 dangerouslySetInnerHTML={{__html: content.headline}}></h4>
                <p dangerouslySetInnerHTML={{ __html: content.body_copy }}></p>
              </div>
              <div className="featured-image">
                <img src={content.featured_image.url} />
              </div>
          <div className={`extra-content desktop`}>
            {content.extra_content.map((extra, index) => (
              <React.Fragment key={index}>
              {extra.type === 'snippet' ?
                  <div className={`${extra.type} content-item total-${content.extra_content.length}`}>
                  { extra.snippet.snippet_quote ?
                      <p className="snippet-quote mobile">{ extra.snippet.snippet_quote}</p>
                      : null
                    }
                  {extra.snippet.snippet_image.url ?
                    <div className="snippet-image">
                    <img src={extra.snippet.snippet_image.url} />
                    </div>
                    : null
                  }
                  <div className={`snippet-content content-container content-container-${index + 1}`}>
                    { extra.snippet.snippet_quote ?
                      <p className="snippet-quote">{ extra.snippet.snippet_quote}</p>
                      : null
                    }
                    { extra.snippet.snippet_link ?
                      <p  className="snippet-copy"><a href={extra.snippet.snippet_link.url}>{extra.snippet.snippet_link.title }</a> {extra.snippet.rest_of_snippet}</p>
                        : !extra.snippet.snippet_link && extra.snippet.rest_of_snippet ?
                        <p  className="snippet-copy"><strong>{extra.snippet.rest_of_snippet}</strong></p>
                        : null
                    }
                  </div>
                </div>
              : extra.type === 'wcwd' ?
              <div className={`${extra.type} content-item total-${content.extra_content.length}`}>
                  <div className="wcwd-icon">
                        <img src={wcwdIcon} className="desktop" />
                        <img src={wcwdIconMobile} className="mobile"/>
                  </div>
                      <div className={`wcwd-content content-container content-container-${index + 1}`}>
                    <h5>What can we do?</h5>
                    <p dangerouslySetInnerHTML={{__html: extra.what_can_we_do}}></p>
                  </div>
                </div>
              : extra.type === 'related' ?
                <div className={`${extra.type} content-item total-${content.extra_content.length}`}>
                  <div className="related-stories-icon">
                          <img src={relatedStoriesIcon} className="desktop" />
                          <img src={relatedStoriesIconMobile} className="mobile" />
                  </div>
                  <div className={`related-stories-content content-container content-container-${index + 1}`}>
                          <p dangerouslySetInnerHTML={{ __html: extra.related_stories.body_copy }}></p>
                          <div className="related-story-links">
                            {extra.related_stories.links.map(link => (
                              <a href={link.link.url} key={link.link.title}>{link.link.title}</a>
                            ))}
                          </div>
                  </div>
                </div>
              : null
              }
              </React.Fragment >
              ))}
            </div>
            <div className={`extra-content mobile`}>
              <Carousel responsive={carouselBreakpoints} showDots={true} infinite={true} renderArrowsWhenDisabled>
              {content.extra_content.map((extra, index) => (
                <React.Fragment key={index}>
                  {extra.type === 'snippet' ?
                      <div className={`${extra.type} content-item total-${content.extra_content.length}`}>
                      { extra.snippet.snippet_quote ?
                          <p className="snippet-quote mobile">{ extra.snippet.snippet_quote}</p>
                          : null
                        }
                      {extra.snippet.snippet_image.url ?
                        <div className="snippet-image">
                        <img src={extra.snippet.snippet_image.url} />
                        </div>
                        : null
                      }
                      <div className={`snippet-content content-container content-container-${index + 1}`}>
                        { extra.snippet.snippet_quote ?
                          <p className="snippet-quote">{ extra.snippet.snippet_quote}</p>
                          : null
                        }
                        { extra.snippet.snippet_link ?
                          <p  className="snippet-copy"><a href={extra.snippet.snippet_link.url}>{extra.snippet.snippet_link.title }</a> {extra.snippet.rest_of_snippet}</p>
                            : !extra.snippet.snippet_link && extra.snippet.rest_of_snippet ?
                            <p  className="snippet-copy"><strong>{extra.snippet.rest_of_snippet}</strong></p>
                            : null
                        }
                      </div>
                    </div>
                  : extra.type === 'wcwd' ?
                  <div className={`${extra.type} content-item total-${content.extra_content.length}`}>
                      <div className="wcwd-icon">
                            <img src={wcwdIcon} className="desktop" />
                            <img src={wcwdIconMobile} className="mobile"/>
                      </div>
                          <div className={`wcwd-content content-container content-container-${index + 1}`}>
                        <h5>What can we do?</h5>
                        <p dangerouslySetInnerHTML={{__html: extra.what_can_we_do}}></p>
                      </div>
                    </div>
                  : extra.type === 'related' ?
                    <div className={`${extra.type} content-item total-${content.extra_content.length}`}>
                      <div className="related-stories-icon">
                              <img src={relatedStoriesIcon} className="desktop" />
                              <img src={relatedStoriesIconMobile} className="mobile" />
                      </div>
                      <div className={`related-stories-content content-container content-container-${index + 1}`}>
                              <p dangerouslySetInnerHTML={{ __html: extra.related_stories.body_copy }}></p>
                              <div className="related-story-links">
                                {extra.related_stories.links.map(link => (
                                  <a href={link.link.url} key={link.link.title}>{link.link.title}</a>
                                ))}
                              </div>
                      </div>
                    </div>
                  : null
                  }
                  </React.Fragment >
                ))}
              </Carousel>
              </div>
            </div>
            : null
          }
    </>
  )
}
