import { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { API_URL } from 'assets';
import './TagsList.scss';

export default function TagsList({ pageData }) {
  const [tags, setTags] = useState([]);
  const isCategoryPage = useLocation().pathname.indexOf('category') > -1;
  const category = useParams().slug;

  useEffect(() => {
    const getTags = async () => {
      const response = await fetch(`${API_URL}/categories?per_page=99&_fields=id,count,name,slug,parent,acf&exclude=1`);
      let data = await response.json();

      if (isCategoryPage) data = data.filter(item => item.parent === pageData.id);
      data.sort((a, b) => {
        if (a.count > b.count) return -1;
        if (a.count < b.count) return 1;
        return 0;
      });

      setTags(data);
    }

    getTags();
  }, [pageData, isCategoryPage]);

  useEffect(() => {
    console.log(tags);
  }, [tags])

  if (!tags.length) return null;

  return (
    <section className="tags-list">
      <div className="container">
        {isCategoryPage ?
          <h2>Browse all <span>{category} tags</span></h2> :
          <h2>Article Tags</h2>
        }

        <div className={`tags-list-content ${isCategoryPage ? "category" : ""}`}>
          {tags.map(tag => (
            <>
              {tag.parent !== 0 ?
                <Link className="tag" to={`/editorial/tag/${tag.slug}`} key={tag.id} style={{ '--colorScheme': tag.acf.color }}>
                    <div className="tag-img">
                      {tag.acf.featured_image && <img src={tag.acf.featured_image} alt="" />}
                    </div>

                  <span dangerouslySetInnerHTML={{ __html: tag.name }}></span>
                </Link>
                :
                null
              }
            </>
          ))}
        </div>
      </div>
    </section>
  )
}
