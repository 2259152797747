import { useEffect, useState, useContext } from 'react'
import { API_URL } from 'assets';
import { Link, NavLink } from 'react-router-dom';
import Lottie from 'lottie-react';
import animationData from './assets/ticker.json';
import './LatestNews.scss'


export default function LatestNews({ data }) {
  const [post, setPost] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      const postResponse = await fetch(`${API_URL}/posts/${data.featured_news_article}?_embed=author,wp:term&_fields=id,slug,title,author,acf,excerpt,_links.wp:term,_embedded.wp:term,_links.author,_embedded.author`);
      const postItems = await postResponse.json();

      setPost(postItems);
    }

    getPosts();
  }, [data.featured_news_article])

  const AnimationComponent = () => {
    return (
      <Lottie
        animationData={animationData}
        loop={true}
        autoplay={true}
        style={{ width: '100%', height: '100%' }}
      />
    );
  };

  if (!post) return null;

  return (
    <section className="latest-news">
      <AnimationComponent />
      <div className="article-display">
        <div className="background">
          <div className="bg-image">
            <img src={post.acf.featured_images[0].image} />
          </div>
        </div>
        <div className="container">
          <div className="article-content">
            <h3 dangerouslySetInnerHTML={{ __html: post.title.rendered }}></h3>
            <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></p>
            <a href={`/editorial/${post.slug}`} className="learn-more">Learn More</a>
          </div>
        </div>
      </div>
    </section>
  )
}
