import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import { API_URL } from 'assets';
import './Series.scss'

import Lottie from 'lottie-react';
import LoadingAnimation from 'assets/loading-loop.json'

import Hero from './components/Hero/Hero'
import ArticlesGrid from './components/ArticlesGrid/ArticlesGrid'

export default function Series() {

  const params = useParams();
  const [series, setSeries] = useState(null);

  useEffect(() => {
    const getSeries = async () => {
      const seriesResponse = await fetch(`${API_URL}/series?slug=${params.slug}`);
      const seriesItems = await seriesResponse.json();
      setSeries(seriesItems[0]);
    }

    getSeries();
  }, []);

  useEffect(() => {
    console.log('a test');
  }, [])

  if (!series) return (
    <div className="container">
      <Lottie animationData={LoadingAnimation} loop={true} className="loading-animation"/>
    </div>
  );


  return (
    <div className="page individual-series">
      <Helmet>
        <title>{series.name}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <Hero series={series} />
      <ArticlesGrid series={series}/>

    </div>
  )
}
