import { useEffect, useState, useRef } from 'react'
import './ClimateChange.scss';

import SunGif from './../../assets/ccc-sun-2.gif';
import StormGif from './../../assets/ccc-storm-2.gif';

export default function ClimateChange() {
  const [calloutActive, setCalloutActive] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = elementRef.current;
      const elementRect = element.getBoundingClientRect();
      const isVisible = elementRect.top >= 0 && elementRect.bottom <= window.innerHeight;
      setCalloutActive(isVisible);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="ccc-climate" id="challenge" data-progress="challenge">
      <div className="container">

        <div className="ccc-climate-intro">
          <h2>Climate change is real.</h2>
          <p>In August 2021, the United Nations’ Intergovernmental Panel on Climate Change released its latest report, and the findings are grim:</p>
        </div>

        <div className="ccc-cols ccc-icons">
          <div className="ccc-col">
            <div className="ccc-icon">
              <img src={SunGif} alt="" width="145px" />
            </div>
            <p>The last decade was hotter than<br /> any period in <strong>125,000 years</strong>.</p>
          </div>
          <div className="ccc-col">
            <div className="ccc-icon">
              <img src={StormGif} alt="" width="205px" />
            </div>
            <p>Scientists can now link <strong>extreme<br /> weather to human activity</strong>; it’s<br /> clearly our fault. </p>
          </div>
        </div>

        <div ref={elementRef} className={`ccc-callout animate-in-view ${calloutActive ? 'active' : ''}`}>
          <p>UN Secretary-General António Guterres called the report&nbsp;a<br /> <strong className="red">Code Red for humanity.</strong> <strong>Climate change is here. There is no time to waste.</strong></p>
        </div>

        <div className="ccc-climate-address">
          <h2>Every sector of the economy must address this crisis.</h2>
          <p>That includes emerging fields like cryptocurrencies, which are exploding into the mainstream by offering the prospect of an inclusive and more efficient financial system.</p>

          <div className="ccc-cols">
            <div className="ccc-col">
              <h3>Proof-of-Work (PoW) technology is a colossal and growing source of CO<sub>2</sub> emissions.</h3>
              <p>PoW’s energy demands and carbon footprint are already unsustainably high. That results in 63 million tons of CO<sub>2</sub> every year.</p>
            </div>
            <div className="ccc-col">
              <h3>Bitcoin consumes an average of 132 TWh/year, equal to roughly 12 million US homes.</h3>
              <p>Many major cryptocurrencies, including Bitcoin, use PoW technology to validate transactions. By design, this uses massive amounts of energy.</p>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}
