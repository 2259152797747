import { useEffect, useState, useRef } from 'react';
import './FeaturedArtist.scss';

import Check from './../../assets/check.svg';

export default function FeaturedArtist({onFormSubmit}) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    step1: { name: '', email: '', location: '', phone: '' },
    step2: { field1: [], field2: ''},
    step3: { link1: '', link2: '', link3: '', field4: false, field5: false },
  });
  const [stepValidity, setStepValidity] = useState({
    step1: false,
    step2: false,
    step3: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const currentStepData = { ...formData[`step${step}`] };

    if (step === 2 && type === 'checkbox') {

      let selectedOptions = [...currentStepData[name]];

      if (checked) {
        selectedOptions.push(value);
      } else {
        selectedOptions = selectedOptions.filter((option) => option !== value);
      }

      currentStepData[name] = selectedOptions;
    } else if (step === 3 && type === 'checkbox') {
      currentStepData[name] = type === 'checkbox' ? checked : value;
    } else {
      currentStepData[name] = value;
    }

    setFormData({ ...formData, [`step${step}`]: currentStepData });
  };


  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    onFormSubmit(true);
  };

  const handleValidation = () => {
    const currentStepData = { ...formData[`step${step}`] };
    let isValid = true;

    // Check if any required fields in the current step are empty
    for (const key in currentStepData) {
      if (currentStepData.hasOwnProperty(key)) {
        if (
          typeof currentStepData[key] === 'string' &&
          currentStepData[key].trim() === '' &&
          document.getElementsByName(key)[0].hasAttribute('required')
        ) {
          isValid = false;
          break;
        }
        if (
          Array.isArray(currentStepData[key]) &&
          currentStepData[key].length === 0 &&
          document.getElementsByName(key)[0].hasAttribute('required')
        ) {
          isValid = false;
          break;
        }
      }
    }

    setStepValidity({ ...stepValidity, [`step${step}`]: isValid });
  };


  useEffect(() => {
    handleValidation();
  }, [step, formData]);


  return (
    <div className="featured-form form-content">
    <div className="progress-bar">
      <div className="progress progress-1">
        <div className={`display ${step >= 1 ? 'active' : ''}`}>1</div>
        <span>Contact info</span>
      </div>
      <div className="progress progress-2">
        <div className={`display ${step >= 2 ? 'active' : ''}`}>2</div>
        <span>Interested topics</span>
      </div>
      <div className="progress progress-3">
        <div className={`display ${step >= 3 ? 'active' : ''}`}>3</div>
        <span>Work submission</span>
        </div>
    </div>
    <form onSubmit={handleSubmit}>
      {step === 1 && (
        <div className="step step-1">
          <div className="step-title">
            <h2>Let's start with the basics</h2>
            <p>* Indicates a required field</p>
          </div>
          <input
            type="text"
            name="name"
            value={formData.step1.name}
            placeholder="First and last name *"
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="email"
            value={formData.step1.email}
            placeholder="Email *"
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="location"
            value={formData.step1.location}
            placeholder="Location (Country, State, or City) *"
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="phone"
            value={formData.step1.phone}
            placeholder="Phone number "
            onChange={handleChange}
            pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          />
        </div>
      )}
      {step === 2 && (
        <div className="step step-2">
          <div className="step-title">
            <h2>Which category does your work firt into best?</h2>
            <p>* Indicates a required field</p>
          </div>
          <div className="select-applicable">
            <p>Everything we do is to help advocate for peace and prosperity for people and the planet, now and into the future. If the work you're doing does too, we love it already. Select all that apply. *</p>
            <div className="selections">
              <label className={`${formData.step2.field1.includes('Climate') ? 'checked' : ''}`}>
                <input
                  type="checkbox"
                  name="field1"
                  value="Climate"
                  checked={formData.step2.field1.includes('Climate')}
                  onChange={handleChange}
                />
                Climate
              </label>
              <label className={`${formData.step2.field1.includes('Equality') ? 'checked' : ''}`}>
                <input
                  type="checkbox"
                  name="field1"
                  value="Equality"
                  checked={formData.step2.field1.includes('Equality')}
                  onChange={handleChange}
                />
                Equality
              </label>
              <label className={`${formData.step2.field1.includes('Justice') ? 'checked' : ''}`}>
                <input
                  type="checkbox"
                  name="field1"
                  value="Justice"
                  checked={formData.step2.field1.includes('Justice')}
                  onChange={handleChange}
                />
                Justice
              </label>
              <label className={`${formData.step2.field1.includes('Sustainability') ? 'checked' : ''}`}>
                <input
                  type="checkbox"
                  name="field1"
                  value="Sustainability"
                  checked={formData.step2.field1.includes('Sustainability')}
                  onChange={handleChange}
                />
                Sustainability
                </label>
                <label className={`${formData.step2.field1.includes('Wellness') ? 'checked' : ''}`}>
                <input
                  type="checkbox"
                  name="field1"
                  value="Wellness"
                  checked={formData.step2.field1.includes('Wellness')}
                  onChange={handleChange}
                />
                Wellness
              </label>
            </div>
          </div>
          <textarea
            type="textarea"
            name="field2"
            value={formData.step2.field2}
            placeholder="Tell us more about your ideas or categories you might want to cover."
            onChange={handleChange}
          >
          </textarea>
        </div>
      )}
      {step === 3 && (
        <div className="step step-3">
          <div className="step-title">
            <h2>Drop a link or two so we can see your work, then you're all set!</h2>
            <p>* Indicates a required field</p>
          </div>
          <p>Upload links to social media, your website, or even a Google Doc.</p>
          <input
            type="text"
            name="link1"
            value={formData.step3.link1}
            placeholder="Link *"
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="link2"
            value={formData.step3.link2}
            placeholder="Link"
            onChange={handleChange}
          />
          <input
            type="text"
            name="link3"
            value={formData.step3.link3}
            placeholder="Link"
            onChange={handleChange}
            />
          <div className="acknowledgements">
          <div className="acknowledgement">
            <label>
              <input
                type="checkbox"
                name="field2"
                checked={formData.step3.field2}
                onChange={handleChange}
                required
                />
              <div className={`custom-box ${formData.step3.field2 ? 'checked' : ''}`}><img src={Check} className="check"/></div>
              I agree to the terms and conditions
            </label>
          </div>
          <div className="acknowledgement">
            <label>
              <input
                type="checkbox"
                name="field3"
                checked={formData.step3.field3}
                onChange={handleChange}
              />
                <div className={`custom-box ${formData.step3.field3 ? 'checked' : ''}`}><img src={Check} className="check"/></div>
              Subscribe to newsletter
            </label>
            </div>
          </div>
        </div>

      )}
      <div className="form-nav">
        <button type="button" onClick={handlePrevious}>
          Back
        </button>
        {step < 3 && (
          <button type="button" onClick={handleNext} disabled={!stepValidity[`step${step}`]}>
            Next
          </button>
          )}
          {step === 3 && (
            <button type="submit">Submit</button>
          )}
      </div>
    </form>
  </div>
  )
}
