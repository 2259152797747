import { useEffect, useState } from 'react';
import './Submission.scss';

import SubmissionImage from './../../assets/get-involved-submission.png';

export default function Submission() {

  return (
    <div className="form-submission-display">
      <div className="container">
        <h2>Thanks!< br /> We'll be in touch.</h2>
        <p>Thanks for your interest in working with us. We're excited to review your submission. Keep an eye on your email, we'll reach out to you soon!</p>
        <div className="cta">
          <a href="#">Explore our programs</a>
        </div>
        <img src={SubmissionImage} />
      </div>
    </div>
  )
}
