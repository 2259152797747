import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { API_URL } from 'assets';
import './NewsPress.scss';

import Toggle from './components/Toggle/Toggle';
import News from './components/News/News';
import Press from './components/Press/Press';

// import Component from './components/Component'

import Lottie from 'lottie-react';
import LoadingAnimation from 'assets/loading-loop.json'

export default function NewsPress() {
  const [page, setPage] = useState(null);
  const [toggleStatus, setToggleStatus] = useState(null);

  const handleToggleStatus = (data) => {
    setToggleStatus(data);
  }

  useEffect(() => {
    const getPage = async () => {
      const pageResponse = await fetch(`${API_URL}/pages/874?v=3`);
      const pageData = await pageResponse.json();
      setPage(pageData);
    }

    getPage();
  }, []);


  if (!page) return (
      <div className="container">
        <Lottie animationData={LoadingAnimation} loop={true} className="loading-animation"/>
      </div>
  );

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page news-and-press' }}>
        <title>Arts Help | News &amp; Press</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <Toggle toggleStatus={handleToggleStatus} />

      {/* {!toggleStatus ?
        <News intialLoad={page} />
        :
        <Press initialLoad={page} />
      } */}

      <div className={`page-content-container ${toggleStatus ? "press-active" : "news-active"}`}>
        <News />
        <Press data={page.acf} />
      </div>

      {/* <div className="page-content-container">
        <News />
        <Press data={page.acf.press_links} />
      </div> */}


    </>
  )
}
