import { useEffect, useState } from 'react'
import './Hero.scss'

import BlackArtistrySeries from './assets/black-artistry-series-365.svg';
import ExclusiveInterviews from './assets/exclusive-interviews-bg.jpg';

export default function Hero(series) {

  const content = series.series;

  const Template = () => {
    if (content.name === "Black Artistry Series 365") {
      return (
        <div className={`series-hero ${content.name.toLowerCase().replace(/ /g, "-")}`}>
          <div className="background-media">
            <img src={content.acf.featured_image} alt="homepage-background" />
          </div>
          <div className="container">
            <h1 style={{ visibility: 'hidden' }}>{content.name}</h1>
              <div className="series-title">
                <img src={BlackArtistrySeries} alt={content.name} aria-label={content.name} title={content.name} />
              </div>
            <div className="series-description">
              <p>{ content.description}</p>
            </div>
          </div>
        </div>
      )
    } else if (content.name === "Exclusive Interviews") {
      return (
        <div className={`series-hero ${content.name.toLowerCase().replace(/ /g, "-")}`}>
          <div className="background-media">
            <img src={ExclusiveInterviews} alt="Exclusive Interviews" />
          </div>
          <div className="container">
            <h1 style={{ visibility: 'hidden', height: '0' }}>{content.name}</h1>
            <div className="series-description">
              <p>{ content.description}</p>
            </div>
          </div>
        </div>
      )
    }

  }

  useEffect(() => {
    console.log(content.name);
  }, [])

  return (
    <Template />
  )
}
