import React, { useEffect, useState } from 'react';
import './ContributingWriterCallout.scss';


export default function ContributingWriterCallout({ data }) {

  useEffect(() => {
    console.log(data);
  }, [data])

  if (!data) return null;

  return (
    <section className="contributing-writer-callout">
      <div className="container">
        <div className="headline" style={{ backgroundImage: `url(${data.background_image.url})`}}>
          <h2>{data.headline}</h2>
        </div>
        <div className="body">
          <p>{data.body_copy}</p>
          <div className="cta cta-1">
            <a href={data.cta_1.url}>{data.cta_1.title}</a>
          </div>
          <div className="cta cta-2">
            <a href={data.cta_2.url}>{data.cta_2.title}</a>
          </div>
        </div>
      </div>
    </section>
  )
}
