import { useEffect, useState } from 'react'
import './Pledge.scss';
import Rellax from 'rellax';

import PledgeGraphic from './../../assets/ccc-pledge.svg';

export default function Pledge() {
  const Graphic = () => {
    const rellaxInit = () => {
      new Rellax('.ccc-pledge-bg', {
        vertical: true,
        center: true,
      });
    }

    useEffect(() => {
      rellaxInit();
    }, []);

    return (
      <img
        src={PledgeGraphic}
        alt="CCC graphic. The text says: Make the CCC pledge. User you art, take a stand."
        className="rellax ccc-pledge-bg"
        data-rellax-vertical-scroll-axis="xy"
        data-rellax-vertical-speed="2.1"
      />
    )
  }

  return (
    <section className="ccc-pledge">
      <div className="container">
        <div className="ccc-cols">
          <div className="ccc-col">
          </div>
          <div className="ccc-col">
            <h2>2. Make the CCC Pledge</h2>
            <p>I pledge to <strong>learn about the environmental impacts</strong> of blockchains and NFTs.</p>
            <p>I pledge to <strong>share this knowledge</strong> with fellow creatives.</p>
            <p>I pledge to <strong>use my art and platform</strong> to advocate for more sustainable crypto technologies.</p>
            <p>I pledge to <strong>advocate for clean NFT platforms</strong>  and transparency in new blockchain technologies, and <strong>NOT engage with those powered by Proof Of Work</strong>.</p>
            <h3><strong>Together we will create a movement to meet the moment. </strong></h3>
          </div>
        </div>
      </div>

      <Graphic />

    </section>
  )
}
