import './Education.scss';

import Blockchain from "./../../assets/ccc-blockchain.svg";
import EnvironmentalImpact from "./../../assets/ccc-env.svg";
import Nfts from "./../../assets/ccc-nft.svg";
import Become from "./../../assets/ccc-become.svg";


export default function Education() {

  return (
    <section className="ccc-edu">
      <div className="container">
        <h2>1. Take our Mini Master classes</h2>
        <p>Complete our Mini Master classes and make the CCC Pledge to become<br /> eligible for part of our <strong>$3.5M Artist Grant</strong>. Class topics include:</p>

        <ul className="ccc-edu-list">
          <li>
            <div className="ccc-edu-item-img">
              <img src={Blockchain} alt="" />
            </div>
            <p><strong>Blockchain &amp; Cryptocurrencies 101</strong></p>
          </li>
          <li>
            <div className="ccc-edu-item-img">
              <img src={EnvironmentalImpact} alt="" />
            </div>
            <p><strong>Environmental Impacts</strong></p>
          </li>
          <li>
            <div className="ccc-edu-item-img">
              <img src={Nfts} alt="" />
            </div>
            <p><strong>All About NFTs</strong></p>
          </li>
          <li>
            <div className="ccc-edu-item-img">
              <img src={Become} alt="" />
              </div>
            <p><strong>Becoming a Conscious Crypto Creator</strong></p>
          </li>
        </ul>

        <div className="ccc-teasers">
          <h3>Watch a preview of what to expect.</h3>
          <a href="/ccc/video" className="trc-button white" aria-label="Watch teaser videos of the CCC classes">Watch now</a>
        </div>
      </div>
    </section>
  )
}
