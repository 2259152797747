import { useEffect, useState, useContext } from 'react'
import './Intro.scss'

export default function Intro({ content }) {

  return (
    <div className="intro">
      <h2 dangerouslySetInnerHTML={{ __html: content.header }}></h2>
      <p dangerouslySetInnerHTML={{__html: content.body_copy}}></p>
    </div>
  )
}
