import './Solutions.scss';
import Change from './../../assets/ccc-change.gif';
import Nft from './../../assets/ccc-nft.gif';


export default function Solutions() {


  return (

    <section className="ccc-solutions" id="solution" data-progress="solution">
    <div className="container">
      <h2 className="ccc-solutions-intro">It doesn’t have to be this way.</h2>

      <div className="ccc-cols">
        <div className="ccc-col">
          <p>Other validation methods, like Proof-of-Stake or Federated Consensus, secure stored value but use a <strong>tiny fraction of the energy compared to the PoW method.</strong><br className="persist" /><br className="persist" />It’s encouraging that many participants in the crypto market are committing to renewable energy sources and offsetting their emissions.</p>
        </div>
        <div className="ccc-col image">
          <img src={Change} alt="Protestor holding sign that says we need a change" className="gif-swap rellax" data-rellax-speed="2.1" />
        </div>
      </div>

      <div className="ccc-cols reverse">
        <div className="ccc-col ccc-col-crypto image">
          <img src={Nft} alt="Depiction of NFTs revolutionizing the law and art" className="gif-swap rellax" data-rellax-speed="2.1" />
        </div>
        <div className="ccc-col">
          <h3>Cryptocurrencies are revolutionizing finance, law, art, and many other areas of life.</h3>
          <p>We must ensure the development and adoption of sustainable, scalable, and value-creating solutions for all. The revolutionary potential in art involves NFTs, which allow artists to monetize and protect their digital artworks. </p>
          <p>Through NFTs, artists can participate in a brand-new economy. They can start or build their careers free from the constraints of traditional financial systems and help ensure that the new economy is built on <strong>sound environmental principles</strong>.</p>
        </div>
      </div>
    </div>
  </section>

  )
}
