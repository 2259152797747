import React, { useEffect, useState } from 'react';
import { API_URL } from 'assets';
import './TeamMembers.scss';


export default function Stats(component) {

  const [teamContent, setTeamContent] = useState(null);
  const [leadership, setLeadership] = useState([]);
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    const getTeamMembers = async () => {
      const pageResponse = await fetch(`${API_URL}/team-members?per_page=99&x=1`);
      const pageData = await pageResponse.json();
      setTeamContent(pageData);
    }

    getTeamMembers();
  }, []);

  useEffect(() => {
    if (teamContent) {
      const { filtered, remaining } = teamContent.reduce(
        (result, member) => {
          if (member.acf.leadership) {
            result.filtered.push(member);
          } else {
            result.remaining.push(member);
          }
          return result;
        },
        { filtered: [], remaining: [] }
      );
      setLeadership(filtered);
      setStaff(remaining);
    }
  }, [teamContent])

  if (!teamContent) return null;

  return (
    <section className="team-members">
      <div className="container">
        <div className="leadership">
          <div className="section-title">
           <h2>Team members</h2>
          </div>
          {leadership.map((leader, index) => (
            <React.Fragment key={leader.title.rendered}>
              {leader.acf.leadership ?
                <div className={`leader leader-${index} ${leader.title.rendered.replace(" ", "-").toLowerCase()}`} >
                  <div className="headshot">
                    <img src={leader.acf.headshot.url} alt={leader.title.rendered} />
                  </div>
                  <div className="about">
                    <p className="name">{leader.title.rendered}</p>
                    <p className="title">{leader.acf.job_title}</p>
                    <p className="bio">{leader.acf.bio}</p>
                  </div>
                </div>
                : null
              }
            </React.Fragment>
          ))}
        </div>
        <div className="staff">
        {staff.map(staff => (
            <React.Fragment key={staff.title.rendered}>
              {!staff.acf.leadership ?
                <div className="staff-member" key={staff.title.rendered}>
                  <div className="headshot"></div>
                  <p className="name" dangerouslySetInnerHTML={{__html: staff.title.rendered}}></p>
                  <p className="title" dangerouslySetInnerHTML={{__html: staff.acf.job_title}}></p>
                </div>
                : null
              }
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  )
}
