import './StepsCopy.scss';


export default function StepsCopy() {

  return (
    <section className="ccc-copy" id="program" data-progress="program">
      <div className="container">
        <h2>Participate in a brand-new cultural economy<br /> to combat climate change </h2>
        <p>Conscious Crypto Creators educates artists on how to use clean NFTs to monetize and protect their digital artworks—all while advocating for sustainable, scalable, and value-creating solutions.</p>
        <p><strong>Join our CCC movement in 3 easy steps.</strong></p>
      </div>
    </section>
  )
}
