import { Helmet } from 'react-helmet';
import './404.scss'

export default function NotFound() {
  return (
    <div className="page not-found">
      <Helmet>
        <title>404</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <div className="container">
        <h2>404</h2>
        <p>Page not found</p>
        <a href="/">Back to Homepage</a>
      </div>
    </div>
  )
}
