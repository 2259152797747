import { useEffect, useState } from 'react';
import { API_URL } from 'assets';
import { Link } from 'react-router-dom';
import './CategoriesNav.scss';
import { useParams } from 'react-router-dom';

export default function CategoriesNav() {
  const params = useParams();
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 767;

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  const toggleDropdown = () => {
    setDropdown(prevDropdown => !prevDropdown);
  }

  useEffect(() => {
    const getCategories = async () => {
      const response = await fetch(`${API_URL}/categories?_fields=id,name,slug,acf,parent&per_page=100&exclude=1,50`);
      const data = await response.json();
      const filteredCategories = data.filter(item => item.parent === 0);
      setCategories(filteredCategories);
    }
    getCategories();
  }, [params.slug]);

  useEffect(() => {
    const categoryPresent = categories.some((item) => item.slug === params.slug);
    categoryPresent ? setActiveCategory(params.slug) : setActiveCategory(null);
  }, [categories])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  if (!categories) return null;

  return (
    <section className="categories-nav">
      <div className="container">
        <nav className={`${dropdown ? 'mobile-active' : null}`} style={{ 'height' : isMobile && dropdown ? 50 * (categories.length + 1)  : 50 }}>
          <div className="mobile-nav-toggle" onClick={toggleDropdown}><span>{activeCategory ? activeCategory + ' + art' : 'Choose category'}</span></div>
          {categories.map(category => (
            <Link to={`/editorial/category/${category.slug}`} className={`nav-item${category.slug === activeCategory ? " active" : ""}`} key={category.id} onClick={isMobile ? toggleDropdown : null}>{category.name} + Art</Link>
          ))}
        </nav>
      </div>
    </section>
  )
}
